import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import FIELD_TYPES from "./FieldTypes";
import { Row, Col } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Validation } from "../../../utils";
// TODO disable, readonly prop integrations
interface Props {
  label?: React.ReactNode;
  placeholder?: string;
  name?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void | any;
  onTextAreaChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  clearSearch?: () => void | any;
  className?: string;
  type?: {};
  value?: any;
  menuPortalTarget?: any;
  minDate?: any;
  maxDate?: any;
  required?: any;
  options?: { value: string | number; label: string | number }[] | any;
  multi?: any;
  handleChange?: any;
  passwordEye?: boolean;
  selected?: any;
  onDateChange?: any;
  minimumDate?: any;
  iconClassName?: string;
  notClearable?: boolean;
  maxLength?: string | number;
  isNumber?: boolean;
  disabled?: boolean;
  menuPlacement?: "top" | "auto" | "bottom";
  isClearable?: boolean;
}

const FieldItem: React.FunctionComponent<Props> = (props: any) => {
  const {
    label,
    placeholder,
    onChange,
    onTextAreaChange,
    name,
    type,
    className,
    value,
    options,
    menuPortalTarget,
    minDate,
    maxDate,
    required,
    multi,
    handleChange,
    passwordEye,
    onDateChange,
    selected,
    minimumDate,
    clearSearch,
    iconClassName,
    notClearable,
    maxLength,
    disabled,
    isNumber,
    menuPlacement,
    isClearable,
  } = props;

  const [eye, seteye] = useState(passwordEye);
  const onEyeClick = () => {
    seteye(!eye);
  };

  const numberValid = (event: any) => {
    const value = event.target.value;
    let pattern = new RegExp(/^[0-9 ]+$/i);
    if (!pattern.test(value) && value !== "") {
      return false;
    } else {
      onChange(event);
    }
  };

  const getInputContainer = () => {
    return (
      <div className="form-group mb-3">
        {label ? (
          <label className="form-label">
            {Validation.capitalizeFirstLetter(label.toString())}
            {required ? (
              <label className="specialcharacter-required">*</label>
            ) : (
              ""
            )}{" "}
          </label>
        ) : (
          ""
        )}

        {getInput()}
      </div>
    );
  };
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      // background: '#fff',
      // borderColor: '#9e9e9e',
      minHeight: "38px",
      height: "38px",
      // boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "38px",
      padding: "0 6px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "38px",
    }),
  };

  const getSortArray = (options: any) => {
    var sortedArray: any[] = options.sort((n1: any, n2: any) => {
      if (
        isNaN(n1.label) &&
        isNaN(n2.label) &&
        n1.label.toUpperCase() > n2.label.toUpperCase()
      ) {
        return 1;
      }

      if (
        isNaN(n1.label) &&
        isNaN(n2.label) &&
        n1.label.toUpperCase() < n2.label.toUpperCase()
      ) {
        return -1;
      }

      if (!isNaN(n1.label) && !isNaN(n2.label)) {
        return n1 - n2;
      }

      return 0;
    });

    let capitalizedArray: any = [];
    sortedArray.length
      ? sortedArray.forEach((x) => {
          isNaN(x.label)
            ? capitalizedArray.push({
                label: Validation.capitalizeFirstLetter(x.label),
                value: x.value,
              })
            : capitalizedArray.push({
                label: x.label,
                value: x.value,
              });
        })
      : options.forEach((x: any) => {
          isNaN(x.label)
            ? capitalizedArray.push({
                label: Validation.capitalizeFirstLetter(x.label),
                value: x.value,
              })
            : capitalizedArray.push({
                label: x.label,
                value: x.value,
              });
        });
    return capitalizedArray;
  };

  const optionsData: any = multi
    ? [{ label: "Select All", value: "all" }, ...options]
    : options;
  const getInput = () => {
    switch (type) {
      case FIELD_TYPES.SEARCH:
        return (
          <div>
            <input
              value={value}
              name={name}
              placeholder={
                placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
              }
              type="text"
              className={className}
              onChange={onChange}
              style={{ width: "90%" }}
            />
            {value ? (
              <i className="fa fa-times searchTable" onClick={clearSearch}></i>
            ) : (
              ""
            )}
          </div>
        );
      case FIELD_TYPES.TEXT:
        return (
          <input
            value={value}
            name={name}
            placeholder={
              placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
            }
            type="text"
            className={className}
            onChange={(e) => (isNumber ? numberValid(e) : onChange(e))}
            maxLength={maxLength ? maxLength : ""}
            disabled={disabled}
          />
        );

      case FIELD_TYPES.TEXT_EYE:
        return (
          <>
            <input
              value={value}
              name={name}
              placeholder={
                placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
              }
              type={eye ? "text" : "password"}
              className={className}
              onChange={onChange}
            />
            <i
              className={`fa fa-eye${eye ? "-slash" : ""}  ${
                iconClassName ? iconClassName : ""
              }`}
              onClick={onEyeClick}
            ></i>
          </>
        );
      case FIELD_TYPES.DROP_DOWN:
        return (
          <Select
            name={name}
            styles={customStyles}
            menuPlacement={menuPlacement}
            placeholder={
              placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
            }
            value={value}
            onChange={onChange}
            className={className}
            options={getSortArray(options)}
            isDisabled={disabled}
            classNamePrefix="Select"
            menuPortalTarget={menuPortalTarget}
            isClearable={notClearable ? false : true}
          />
        );
      case FIELD_TYPES.FILE:
        return (
          <input
            type="file"
            name={name}
            placeholder={
              placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
            }
            className={className}
            onChange={onChange}
          />
        );
      case FIELD_TYPES.PASSWORD:
        return (
          <input
            value={value}
            name={name}
            placeholder={
              placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
            }
            type="password"
            className={className}
            onChange={onChange}
          />
        );
      case FIELD_TYPES.TEXT_AREA:
        return (
          <textarea
            value={value}
            name={name}
            placeholder={
              placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
            }
            // type="text"
            className={className}
            onChange={onTextAreaChange}
          ></textarea>
        );
      case FIELD_TYPES.DATE_RANGE:
        return (
          <input
            value={value}
            name={name}
            placeholder={
              placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
            }
            type="date"
            className={className}
            onChange={onChange}
            min={minDate}
            max={maxDate}
            // min={moment().toDate()}
            // minDate = moment(new Date()).format('YYYY-MM-DD')
          />
        );
      case FIELD_TYPES.MULTI_SELECT:
        return (
          <Select
            // defaultValue={[colourOptions[2], colourOptions[3]]}
            isMulti
            name="colors"
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            // className={className}
          />
        );
      case FIELD_TYPES.SELECT_All_MULTI:
        return (
          <Select
            name="example"
            options={optionsData}
            multi={multi}
            value={value ? value : null}
            onChange={(selected) => {
              multi &&
              selected.length &&
              selected.find((option: any) => option.value === "all")
                ? handleChange(optionsData.slice(1))
                : !multi
                ? handleChange((selected && selected.value) || null)
                : handleChange(selected);
            }}
          />
        );
      case FIELD_TYPES.DATE_PICKER:
        return (
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={value}
            onChange={onDateChange}
            minDate={minDate}
            maxDate={maxDate}
            className={className}
            placeholderText={
              placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
            }
            popperProps={{ strategy: "fixed" }}
            showYearDropdown
            isClearable={isClearable || false }
          />
        );
      default:
        return (
          <input
            value={value}
            name={name}
            placeholder={
              placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
            }
            type="text"
            className={className}
            onChange={onChange}
          />
        );
    }
  };
  const getFinalData = () => {
    // use this function to update parent div style
    return <div>{getInputContainer()}</div>;
  };

  return getFinalData();
};

FieldItem.defaultProps = {
  label: "",
  placeholder: "",
  name: "",
  onChange: (event) => {},
  className: "",
  type: FIELD_TYPES.TEXT,
  value: "",
};

export default FieldItem;

// <DatePicker
//   selected={value}
//   className={className}

//   // onSelect={handleDateSelect} //when day is clicked
//   onChange={onChange} //only when value has changed
// />
