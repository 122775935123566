import React, { Component } from "react";
import { homePageState, homepageProps } from "./interface";
import { AddressBar, Footer, NavigationBar } from "./../../widgets";
import { Switch, Route, Link } from "react-router-dom";
import ViewProjects from "../viewProjects";
import Profile from "../profile";
import InviteFriends from "../inviteFriends";
import AdminDashboard from "../adminDashboard";
import ListProcurement from "../procurementCreation/listProcurement";
import CreateProcurement from "../procurementCreation/createProcurement";
import CreateProject from "../createProject";
import Location from "../location";
import ListProject from "../listProject";
import PhotoView from "../photoView";
import ListPlants from "../plants/listPlants";
import CreatePlant from "../plants/createPlant";
import ListPlant from "../plantMaster";
import ListUsers from "../userManagement/listUsers";
import DistrictLevelDistribution from "../distribution/districtLevel";
import PincodeLevellDistributionState from "../distribution/pincodeLevel";
import CreateBadge from "../badge/createBadge";
import ListBadge from "../badge/listBadge";
import { MainMap } from "../plants/createPlant/partials/map";
import Sapling from "../sapling";
import Plantings from "../plants/plantingsMaster";
import { UserHelper, Validation } from "../../utils";
import {
  ProtectedRoute,
  ProtectedRouteProps,
} from "./partials/protectedRouteComponent";
import TableExample from "../../widgets/table";
import { userRoles } from "../../constants";
import { authenticationService } from "../../service";
import { showToaster, toasterTypes } from "../../widgets";
import { ListDelivery } from "../procurementCreation/listDelivery";
import { ListDispatch } from "../distribution/listDispatch";
import { ListDistribution } from "../distribution/listDistribution";
import VolunteerDashboard, { VisiterList } from "../volunteerLandingPage";
import ErrorBoundary from "../../ErrorBountry";
import { Subscription } from "rxjs";
import {
  projectChangeSubscriber,
  projectDetailsSubscriber,
} from "../../utils/subscriber";
import ProjectListDashboard from "../projectListDashboard";
import { HistoryPlants } from "../historyPlants";
import { ListPaymentHistory } from "../procurementCreation/listPaymentHistory";
import EditPayment from "../procurementCreation/editPaymentHistory";
import ParentProjectList from "../parent-project";
import CategorySetting from "../category-settings";
import AreaSetting from "../area-settings";
import PINCodeSetting from "../pincode-settings";
import ExternalDashboard from "../external-dashboard";
import FarmerList from "../adminDashboard/farmer-details";
import FarmerView from "../adminDashboard/farmer-view";
import StateToPINCodeLevelDistribution from "../distribution/districtLevel/state-to-pincode-level";
import { StatePincodeListDistribution } from "../distribution/listDistribution/state-pincode-list-distribution";
import PlantingList from "../plants/plantingsList";

class HomePage extends Component<homepageProps, homePageState> {
  public projectChangeSubscription: Subscription = new Subscription();
  constructor(props: homepageProps) {
    super(props);
    this.state = {
      links: [
        {
          target: "/adminDashboard",
          name: "DASHBOARDS",
          role: [
            userRoles.SUPER_ADMIN,
            userRoles.STATE_ADMIN,
            userRoles.DISTRICT_ADMIN,
          ],
        },
        {
          target: "/volunteerDashboard",
          name: "Dashboard",
          role: [userRoles.VOLUNTEER],
        },
        {
          target: "/external-dashboard",
          name: "Dashboard",
          role: [userRoles.EXTERNAL],
        },
        {
          target: "/listProject",
          name: "PROJECTS",
          role: [userRoles.SUPER_ADMIN],
        },
        {
          target: "/plantMaster",
          name: "Plants",
          role: [userRoles.SUPER_ADMIN],
        },

        {
          target: "/listProcurement",
          name: "Procurements",
          role: [userRoles.STATE_ADMIN],
        },
        {
          target: "/districtLevelDistribution",
          name: "Distributions",
          role: [userRoles.STATE_ADMIN],
        },

        {
          target: "/pincodeLevelDistribution",
          name: "Distributions",
          role: [userRoles.DISTRICT_ADMIN],
        },
        {
          target: "/listBadge",
          name: "Badge",
          role: [userRoles.SUPER_ADMIN],
        },
        {
          target: "/listUsers",
          name: "User Management",
          role: [
            userRoles.SUPER_ADMIN,
            userRoles.STATE_ADMIN,
            userRoles.DISTRICT_ADMIN,
          ],
        },
        {
          target: "/listPlants",
          name: "Plants",
          role: [userRoles.VOLUNTEER],
        },
        {
          target: "/sapling",
          name: "SAPLING",
          role: [userRoles.DISTRICT_ADMIN],
        },
        {
          target: "/plantings",
          name: "Plantings",
          role: [userRoles.SUPER_ADMIN],
        },
        {
          target: "/category-settings",
          name: "Category Setting",
          role: [userRoles.SUPER_ADMIN],
        },
      ],
      siteData: {},
      projectData: {},
      activeProjectData: {},
      ProjectCradData: [],
      carouselImages: [],
      isLoading: true,
      updatedProjectsLink: [],
    };
  }
  componentDidMount() {
    this.projectChangeSubscription = projectChangeSubscriber.subscribe(
      (response) => {
        this.getMainPageDetails();
        if (!response) {
          this.setState({
            updatedProjectsLink: [
              {
                target: "/listProject",
                name: "PROJECTS",
                role: [userRoles.SUPER_ADMIN],
              },
              {
                target: "/plantMaster",
                name: "Plants",
                role: [userRoles.SUPER_ADMIN],
              },
              {
                target: "/listUsers",
                name: "User Management",
                role: [
                  userRoles.SUPER_ADMIN,
                  userRoles.STATE_ADMIN,
                  userRoles.DISTRICT_ADMIN,
                ],
              },
            ],
          });
        } else {
          this.setState({ updatedProjectsLink: this.state.links });
        }
      }
    );

    this.componentDidUpdate = (prevProps, prevState) => {
      // Typical usage (don't forget to compare props):
      if (
        prevState.projectData &&
        prevState.projectData.name != this.state.projectData.name
      ) {
        projectChangeSubscriber.next(this.state.projectData.name);
      }
    };

    if (!Validation.isUserLogged()) {
      const links = [
        ...this.state.links,
        {
          target: "",
          name: "ABOUT US",
          role: [],
        },
        {
          target: "",
          name: " CONTACT US",
          role: [],
        },
      ];
      this.setState({ links });
    }
  }
  componentWillUnmount() {
    this.projectChangeSubscription.unsubscribe();
  }

  getMainPageDetails = () => {
    try {
      authenticationService.getMainPageData().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            if (
              response.data &&
              Array.isArray(response?.data?.project?.activities) &&
              Array.isArray(response?.data?.project?.images)
            ) {
              projectDetailsSubscriber.next(response.data);
              let rowData: any = [];
              if (response?.data?.state_wise_planted.length > 0) {
                response?.data?.state_wise_planted.forEach((element: any) => {
                  rowData.push({
                    stateName: element.state_name,
                    projectNumber: element.planted_count,
                  });
                });
              }
              this.setState({
                siteData: response?.data,
                projectData: response?.data?.project,
                activeProjectData: response?.data?.project?.activities[0],
                carouselImages: response?.data?.project.images,
                ProjectCradData: rowData,
              });
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  showLoadingPage = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  render() {
    const protectedRouteProps: ProtectedRouteProps = {
      isAuthenticated: Validation.isUserLogged(),
      authenticationPath: "/login",
    };
    const userDetails = UserHelper.getUserDetails();
    const { siteData, projectData, activeProjectData, carouselImages } =
      this.state;
    return (
      <ErrorBoundary>
        {/* {this.props.history.location.pathname !== "/projectListDashboard" || "/listProject"||"/plantMaster"||"/listBadge"||"/listUsers"||"/plantings" ??(
          
        )} */}

        {this.props.history.location.pathname == "/" ||
        this.props.history.location.pathname == "/aboutUs" ? (
          <>
            {/* <NavigationBar
              history={this.props.history}
              location={this.props.location}
              match={this.props.match}
              links={this.state.updatedProjectsLink}
              isLogged={Validation.isUserLogged()}
              username={
                userDetails
                  ? `${userDetails.first_name} ${userDetails.last_name}`
                  : ""
              } */}
            {/* /> */}
          </>
        ) : (
          <>
            <AddressBar
              mobileNumber={siteData.contactno1}
              email={siteData.email1}
              twitterAccountLink={siteData.twitter}
              facebookAccountLink={siteData.facebook}
              linkedInAccountLink={siteData.pinterest}
              instagramAccountLink={siteData.instagram}
              history={this.props.history}
            />

            <NavigationBar
              history={this.props.history}
              location={this.props.location}
              match={this.props.match}
              links={this.state.updatedProjectsLink}
              isLogged={Validation.isUserLogged()}
              username={
                userDetails
                  ? `${userDetails.first_name} ${userDetails.last_name}`
                  : ""
              }
            />
          </>
        )}

        <Switch>
          <Route
            path="/"
            exact
            render={(props) => (
              <ViewProjects
                plantedTrees={siteData.total_trees_planted}
                carouselImages={carouselImages}
                projectName={projectData.name}
                projectDescription={projectData.description}
                activeProjectName={activeProjectData.description}
                activeProjectDescription={activeProjectData.tree_description}
                ProjectCard={this.state.ProjectCradData}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/projectListDashboard"
            exact
            render={(props) => <ProjectListDashboard />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/profile"
            exact
            render={(props) => <Profile />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/volunteerDashboard"
            exact
            render={(props) => (
              <VolunteerDashboard projectId={projectData.id} />
            )}
          />

          <ProtectedRoute
            {...protectedRouteProps}
            path="/external-dashboard"
            exact
            render={(props) => (
              <ExternalDashboard
                {...props}
                projectId={projectData.id}
                projectName={projectData.name}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/list-invites"
            exact
            render={(props) => <VisiterList />}
          />

          <ProtectedRoute
            {...protectedRouteProps}
            path="/inviteFriends"
            exact={true}
            component={InviteFriends}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            exact
            path="/table"
            component={TableExample}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/adminDashboard"
            exact
            render={(props) => (
              <AdminDashboard
                {...props}
                projectId={projectData.id}
                projectName={projectData.name}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/listProcurement"
            exact
            render={(props) => <ListProcurement projectId={projectData.id} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/createProject/:edit?"
            exact
            render={(props) => <CreateProject {...props} />}
          />
          {/* <ProtectedRoute
            {...protectedRouteProps}
            path="/createPaymentHistory/:edit?"
            exact
            render={(props) => <EditPayment {...props} />}
          /> */}
          <ProtectedRoute
            {...protectedRouteProps}
            path="/createPaymentHistoryprocurement/:procurementId?/:edit?"
            exact
            render={(props) => <EditPayment {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/location"
            exact
            render={(props) => <Location />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/listProject"
            exact
            render={(props) => <ListProject projectId={projectData.id} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/category-settings"
            exact
            render={(props) => <CategorySetting />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/area-settings/:categoryId?"
            exact
            render={(props) => <AreaSetting {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/pincode-settings/:categoryId?/:districtId?"
            exact
            render={(props) => <PINCodeSetting {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/parent-project-list"
            exact
            render={(props) => <ParentProjectList {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            exact
            path="/sapling"
            render={(props) => <Sapling projectId={projectData.id} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/photoView/:userId?"
            exact
            render={(props) => <PhotoView {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/listPlants"
            exact
            render={(props) => <ListPlants projectId={projectData.id} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/createPlant/:edit?"
            exact
            render={(props) => (
              <CreatePlant {...props} projectId={projectData.id} />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/listUsers"
            render={(props) => <ListUsers projectId={projectData.id} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/districtLevelDistribution"
            render={(props) => (
              <DistrictLevelDistribution
                projectName={projectData.name}
                projectId={projectData.id}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/farmer-list/:stateId?/:districtId?/:pincodeId?"
            render={(props) => (
              <FarmerList {...props} projectId={projectData.id} />
            )}
          />

          <ProtectedRoute
            {...protectedRouteProps}
            path="/state-pincodeLevelDistribution"
            render={(props) => (
              <StateToPINCodeLevelDistribution
                projectId={projectData.id}
                projectName={projectData.name}
              />
            )}
          />

          <ProtectedRoute
            {...protectedRouteProps}
            path="/pincodeLevelDistribution"
            render={(props) => (
              <PincodeLevellDistributionState
                projectId={projectData.id}
                projectName={projectData.name}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/createBadge/:edit?"
            exact
            render={(props) => <CreateBadge {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/ListBadge"
            render={(props) => (
              <ListBadge
                projectId={projectData.id}
                projectName={projectData.name}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/mainMap"
            render={(props) => <MainMap />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/plantMaster"
            render={(props) => <ListPlant />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/plantings"
            render={(props) => <Plantings projectId={projectData.id} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/plantings-list"
            render={(props) => <PlantingList projectId={projectData.id} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/createProcurement/:edit?"
            render={(props) => <CreateProcurement {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/listDelivery/:procurementId?"
            render={(props) => (
              <ListDelivery
                {...props}
                projectId={projectData.id}
                projectName={projectData.name}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/listPaymentHistory/:procurementId?"
            render={(props) => <ListPaymentHistory {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/listDispatch/:districtId?"
            render={(props) => <ListDispatch {...props} />}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/listDistribution/:pincodeId?/:districtId?"
            render={(props) => (
              <ListDistribution
                {...props}
                projectId={projectData.id}
                projectName={projectData.name}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/state-pincode-distribution/:pincodeId?/:districtId?"
            render={(props) => (
              <StatePincodeListDistribution
                {...props}
                projectId={projectData.id}
                projectName={projectData.name}
              />
            )}
          />
          <ProtectedRoute
            {...protectedRouteProps}
            path="/historyPlants/:historyId?"
            render={(props) => <HistoryPlants {...props} />}
          />
          {/* <ProtectedRoute
            {...protectedRouteProps}
            path="/spinner"
            render={(props) => <Loader isLoading={true} />}
          /> */}
        </Switch>
        <section className="bgfooter  ">
          <div className="container">
            <div className="corow">
              {/* <div className="col-lg-3 col-md-3 col-xs-6 px-3 d-none d-md-block footer-info"> */}
              {/* <div className="footerhead p mt-2 mb-2 text-black ">About Us</div> */}
              {/* <img
                  src={`${process.env.PUBLIC_URL}/assets/img/Vruksha_logo_white.png`}
                  alt="logo"
                  /> */}
              {/* <p className="p mt-2 mb-2 text-black">
                  The Art of Living is a multi-faceted, non-profit organization
                  with a global presence. Founded in 1981 by a world renowned
                  humanitarian and spiritual leader, Gurudev Sri Sri Ravi
                  Shankar ji. */}
              {/* {props.about_art_of_living} */}
              {/* Operating in 156 countries, The Art of Living is a non-profit, educational and humanitarian organization founded in 1981 by the world-renowned humanitarian and spiritual teacher - Gurudev Sri Sri Ravi Shankar. All our programs are guided by Gurudev’s philosophy: “Unless we have a stress-free mind and a violence-free society, we cannot achieve world peace.” */}
              {/* </p> */}
              {/* </div> */}
              <div className="col-lg-5 col-md-2 col-xs-6 px-3 footer-links" style={{whiteSpace: "nowrap"}}>
                <div>
                  <Link to="/privacyPolicy" className="footerhead ">
                    Privacy Policy
                  </Link>
                </div>
                <div>
                  <Link to="/termsAndCondition" className="footerhead ">
                    Terms & Conditions
                  </Link>
                </div>
                {/* <div className="footerhead ">Privacy & Policy</div> */}
                {/* <img
                src={`${process.env.PUBLIC_URL}/assets/img/Vruksha_logo_white.png`}
                alt="logo"
              /> */}
              </div>

              <div className="col-lg-4 col-md-3 col-xs-5 px-2 footer-links">
                <div className="row">
                <div>
                  <Link to="/" className="footerhead ">
                    Home
                  </Link>
                </div>
                <div>
                  <Link to="/login" className="footerhead ">
                    Sign In
                  </Link>
                </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-xs-5 px-2 footer-links" style={{whiteSpace: "nowrap"}}>
                <div>
                  <Link to="/signup" className="footerhead ">
                    Register
                  </Link>
                </div>
                <div>
                  <Link to="/aboutUs" className="footerhead ">
                    About Us
                  </Link>
                </div>
              </div>

              {/* <div className="col-lg-2 col-md-2 col-xs-5 px-2 footer-links"> */}
                {/* <div>
                  <Link to="/testimonials" className="footerhead ">
                    Testimonials
                  </Link>
                </div>
                <div>
                  <Link to="/" className="footerhead ">
                    Projects
                  </Link>
                </div> */}
              {/* </div> */}

              {/* <div className="col-lg-3 col-md-2 col-xs-5 px-2 footer-links">
                <div className="footerhead"> Social Media</div>
                <div className="d-flex align-items-center ">
                  <div className="social-links  mt-4 px-2">
                    <a
                      target={"_blank"}
                      href="https://twitter.com/ArtofLiving"
                      className="twitter"
                    >
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a
                      target={"_blank"}
                      href="https://facebook.com/ArtofLiving"
                      className="facebook px-1 "
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a
                      target={"_blank"}
                      href="https://instagram.com/artofliving"
                      className="instagram px-1"
                    >
                      <i className="bi bi-instagram"></i>
                    </a> */}
              {/* <Link
                      to={siteData.linkedInAccountLink}
                      className="linkedin px-1"
                    >
                      <i className="bi bi-linkedin"></i>
                    </Link> */}
              {/* </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <div className="footer-content2 ">
          <footer id="footer-new">
            <div className="">
              <div className="">
                <div className="">
                  <div className="">
                    <div className="footText">
                      &copy; Copyright{" "}
                      <strong>
                        <span>Mission Green Earth</span>
                      </strong>
                      . All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
        {/* <Footer
          mobileNumber={siteData.contactno1}
          email={siteData.email1}
          about_art_of_living={siteData.about_art_of_living}
          about_founder={siteData.about_founder}
          twitterAccountLink={siteData.twitter}
          facebookAccountLink={siteData.facebook}
          linkedInAccountLink={siteData.pinterest}
          instagramAccountLink={siteData.instagram}
        /> */}
      </ErrorBoundary>
    );
  }
}

export default HomePage;
