import { fetchCall, requestMethod } from "../../utils/ajax";

const ACTIVESTATUS = "active";
const INACTIVESTATUS = "inactive";
const BOTHSTATUS = "active,inactive,completed";
const COMPLETED = "completed";

const getAllProjectList = (
  page: number = 1,
  pageSize: number = 100,
  key: string = "",
  status: string = BOTHSTATUS,
  parentProjectId: string = ""
) =>
  fetchCall(
    `/projects/list?page=${page}&size=${pageSize}&sort=id&order=desc&status=${status}&keyword=${key}&parant_project_id=${parentProjectId}`,
    requestMethod.GET,
    {}
  );

const getAllProjects = () =>
  fetchCall(`/users/user_project_list`, requestMethod.GET, {});

const getAllProjectListSelect = (
  userId: string = "",
  status: string = BOTHSTATUS
) =>
  fetchCall(
    `/projects/project_list?status=${status}&user_id=${userId}`,
    requestMethod.GET,
    {}
  );
// console.log(getAllProjectList);
const getAllTreeList = () =>
  fetchCall("/master/get_trees", requestMethod.GET, {}, false);

const getAllParentList = () =>
  fetchCall("/projects/load_parent_project", requestMethod.GET, {}, false);
// console.log(getAllParentList)

const createProjectParent = (userData: any) =>
  fetchCall("/projects/save", requestMethod.POST, userData);

const createProjectRequest = (userData: any) =>
  fetchCall("/projects/save", requestMethod.POST, userData);

const projectImageUploadRequest = (data: FormData) =>
  fetchCall("/files/upload", requestMethod.POST, data, {}, true);

const getprojectDetailById = (ProjectId: any) =>
  fetchCall(`/projects/detail?project_id=${ProjectId}`, requestMethod.GET);

const projectChangeStatus = (userData: any) =>
  fetchCall("/projects/change_status", requestMethod.POST, userData);

const parentProjectChangeStatus = (statusData: any) =>
  fetchCall(
    "/projects/change_parent_project_status",
    requestMethod.POST,
    statusData
  );

const getProjectCardData = () =>
  fetchCall("/users/registered_states", requestMethod.GET, {}, false);

const getRegisteredCategoryByData = () =>
  fetchCall(
    "/users/registered_category_by_project",
    requestMethod.GET,
    {},
    false
  );
// const getProjectCardData = () =>
// fetchCall("/users/registered_states", requestMethod.GET, {}, false);

const changeProjectOfUser = (project: any) =>
  fetchCall("/users/save_active_project", requestMethod.POST, project);
const getStatus = () =>
  fetchCall("/projects/load_project_status", requestMethod.GET);

const projectService = {
  getAllProjectList,
  getAllProjectListSelect,
  getAllTreeList,
  createProjectRequest,
  createProjectParent,
  projectImageUploadRequest,
  getprojectDetailById,
  projectChangeStatus,
  parentProjectChangeStatus,
  getProjectCardData,
  getAllProjects,
  changeProjectOfUser,
  getAllParentList,
  getRegisteredCategoryByData,
  getStatus,
  ACTIVESTATUS,
  INACTIVESTATUS,
  BOTHSTATUS,
  COMPLETED,
};

export { projectService };
