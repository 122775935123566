import { showToaster, toasterTypes } from "../../widgets";

export default class Validation {
  // static isValidEmail = (email: string): boolean => {
  //   if (
  //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
  //       email
  //     )
  //   ) {
  //     if (email.length <= 0) {
  //       showToaster(
  //         toasterTypes.ERROR,
  //         `Please enter ${Validation.capitalizeFirstLetter('Please enter valid email')}`
  //       );
  //       return true;
  //     }
  //   }
  //   return false;
  // };
  static isValidEmail = (email: string): boolean => {
    if (email.length >= 0) {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        showToaster(toasterTypes.ERROR, "Please enter a valid email address.");
        return true;
      }
    }
    return false;
  };
  // regex: /^[a-zA-Z0-9 "'_\-\&]+$/i,
  static vaildNnumber = (number: any) => {
    if (/^[0-9]+$/.test(number)) {
      return true;
    }
    return false;
  };

  static capitalizeFirstLetter = (string: string) => {
    let finalString = "";
    string.split(" ").forEach((str) => {
      finalString += str.charAt(0).toUpperCase() + str.slice(1) + " ";
    });
    return finalString;
  };

  static capitalizeFirstLetterForSentence = (word: string) => {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  };

  static isTextRequired = (name: string, message: string): boolean => {
    if (name.length <= 0) {
      showToaster(
        toasterTypes.ERROR,
        Validation.capitalizeFirstLetterForSentence(
          `Please enter ${Validation.capitalizeFirstLetter(message)}`
        )
      );
      return true;
    } else if (name.length > 0) {
      let pattern = new RegExp(/^[a-zA-Z0-9 "'_\-\\&]+$/i);
      if (!pattern.test(name)) {
        showToaster(
          toasterTypes.ERROR,
          Validation.capitalizeFirstLetterForSentence(
            `${Validation.capitalizeFirstLetter(
              message
            )} must contain only alphabets and space.`
          )
        );
        return true;
      }
    }
    return false;
  };

  static isSpecialCharacter = (name: string, message: string): boolean => {
    if (name.length > 0) {
      let pattern = new RegExp(/^[a-zA-Z0-9 "'_\-\\&]+$/i);
      if (!pattern.test(name)) {
        showToaster(
          toasterTypes.ERROR,
          Validation.capitalizeFirstLetterForSentence(
            `${Validation.capitalizeFirstLetter(
              message
            )} must contain only alphabets and space .`
          )
        );
        return true;
      }
    }
    return false;
  };
  static isAmountValidation = (name: any, message: string): boolean => {
    if (name.length > 0) {
      let pattern = new RegExp(/^\d{1,10}(\.\d{1,4})?$/);
      if (!pattern.test(name)) {
        showToaster(
          toasterTypes.ERROR,
          Validation.capitalizeFirstLetterForSentence(
            `${Validation.capitalizeFirstLetter(message)}.`
          )
        );
        return true;
      }
    }
    return false;
  };

  static isRequired = (name: string, message: string): boolean => {
    if (name.length <= 0) {
      showToaster(
        toasterTypes.ERROR,
        Validation.capitalizeFirstLetterForSentence(
          `Please enter ${Validation.capitalizeFirstLetter(message)}`
        )
      );
      return true;
    }
    return false;
  };

  static isRequiredForDropdown = (name: string, message: string): boolean => {
    if (name.length <= 0) {
      showToaster(
        toasterTypes.ERROR,
        Validation.capitalizeFirstLetterForSentence(
          `Please select ${Validation.capitalizeFirstLetter(message)}`
        )
      );
      return true;
    }
    return false;
  };

  static isNotNull = (name: {} | any, message: string): boolean => {
    if (name == null) {
      showToaster(
        toasterTypes.ERROR,
        Validation.capitalizeFirstLetterForSentence(
          `Please select the ${Validation.capitalizeFirstLetter(message)}`
        )
      );
      return true;
    }
    return false;
  };
  static isValidMobileNumber = (name: any, message?: string): boolean => {
    // let pattern = new RegExp(/^[1-9]{1}[0-9]{9}$/);
    let pattern = new RegExp(
      /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/
    );
    if (!pattern.test(name)) {
      showToaster(
        toasterTypes.ERROR,
        Validation.capitalizeFirstLetterForSentence(
          "Please enter valid Mobile Number."
        )
      );
      return true;
    }
    return false;
  };

  static isValidPinNumber = (name: any, message?: string): boolean => {
    let pattern = new RegExp(/^([0-9]{6})$/);
    if (!pattern.test(name)) {
      showToaster(
        toasterTypes.ERROR,
        Validation.capitalizeFirstLetterForSentence(
          "Please enter valid Pin Code."
        )
      );
      return true;
    }
    return false;
  };

  static isValidUserName = (name: any, message?: string): boolean => {
    let pattern = new RegExp(/^[a-zA-Z][a-z0-9_.@]+$/);
    if (!pattern.test(name)) {
      showToaster(
        toasterTypes.ERROR,
        Validation.capitalizeFirstLetterForSentence(
          "Please enter valid username"
        )
      );
      return true;
    }
    return false;
  };

  static isValidNumber = (name: any, message: string): boolean => {
    let pattern = new RegExp(/^[0-9]*$/);
    if (!pattern.test(name)) {
      showToaster(
        toasterTypes.ERROR,
        Validation.capitalizeFirstLetterForSentence(
          `${Validation.capitalizeFirstLetter(
            message
          )} can contain only numeric values.`
        )
      );
      return true;
    }
    return false;
  };

  static isUserLogged = (): boolean => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("user") &&
      localStorage.getItem("id")
    ) {
      return true;
    }
    return false;
  };
}
