import React, { Component } from "react";
import { showToaster, toasterTypes } from "../../../widgets";
import { plantService, projectService, commonService } from "../../../service";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import { Validation } from "../../../utils";
import { constants } from "../../../constants";
import { UncontrolledCarousel } from "reactstrap";
import { plantingsProps, plantingsState } from "./interface";
import moment from "moment";
import Pagination from "../../../widgets/pagination";

class PlantingList extends Component<plantingsProps, plantingsState> {
  constructor(props: plantingsProps) {
    super(props);
    this.state = {
      stateOptions: [],
      selectedFile: [],
      selectedState: null,
      districtOptions: [],
      selectedDistrict: null,
      pincodeOptions: [],
      selectedPincode: null,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  componentDidMount() {
    // this.getProjectCardDetails();
    this.getAllPlantList();
  }

  shouldComponentUpdate(nextProps: any) {
    if (nextProps.projectId && nextProps.projectId !== this.props.projectId) {
      // this.getProjectCardDetails();
      this.getAllPlantList();
    }
    return true;
  }

  getProjectCardDetails = () => {
    projectService.getRegisteredCategoryByData().subscribe(
      (response: any) => {
        try {
          if (response.status && response.statusCode === 200) {
            if (response.data?.length > 0) {
              let rowData: any = [];
              response.data.forEach((element: any) => {
                rowData.push({
                  label: element.name,
                  value: element.id,
                });
              });
              this.setState({ stateOptions: rowData });
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllPlantList(data.selected, pageSize);
  };

  getAllPlantList = (page: number = 1, pageSize: number = 10) => {
    plantService
      .stateDistrictPincodeListing(
        this.state.selectedState?.value,
        this.state.selectedDistrict?.value,
        this.state.selectedPincode?.value,
        page,
        pageSize
      )
      .subscribe(
        (response: any) => {
          let rowData: any = [];
          try {
            if (response.status && response.statusCode === 200) {
              if (response.content?.length > 0) {
                response.content.forEach((element: any) => {
                  let date = moment(element.date_of_planting).format(
                    "DD-MM-YYYY"
                  );
                  let first_name = element.first_name + " " + element.last_name;
                  let status = element.plant_status;
                  let pincode = element.pin_code;
                  let image: any = [];
                  element.images?.forEach((element: any, index: number) => {
                    image.push({
                      key: index,
                      src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                    });
                  });
                  rowData.push({
                    plantedDate: date,
                    dsplayimage: image,
                    first_name: first_name,
                    status: status,
                    pincode: pincode,
                    total_plants_count: element.total_plants_count,
                    dead_plants_count: element.dead_plants_count,
                    live_plants_count: element.live_plants_count,
                  });
                });
                this.setState({
                  pagination: {
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  },
                });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
            this.setState({ selectedFile: rowData });
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
  };

  render() {
    const {
      handlePageClick,
      state: { pagination },
    } = this;

    return (
      <main id="main">
        <section id="MyProfile" className="MyProfile">
          <div className="container" data-aos="fade-up">
            <div className="row ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <h3 className="heading-secondary">Gallery </h3>
                <div className="section-title">
                  <div className="My_Plantings_PhotoViewBox_Body">
                    <ul>
                      {this.state.selectedFile?.map(
                        (element: any, index: number) => (
                          <li key={index}>
                            <div className="My_Plantings_PhotoView">
                              {element.dsplayimage.length === 1 ? (
                                <img
                                  className="object_fit"
                                  src={element.dsplayimage[0].src}
                                  alt="project img"
                                />
                              ) : (
                                ""
                              )}
                              {element.dsplayimage.length > 1 ? (
                                <UncontrolledCarousel
                                  controls={true}
                                  indicators={false}
                                  items={element.dsplayimage}
                                />
                              ) : (
                                ""
                              )}
                              {/* <UncontrolledCarousel items={element.dsplayimage} /> */}
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  {Array.isArray(this.state.selectedFile) &&
                  this.state.selectedFile.length ? (
                    <div className="col-md-12 mt-2">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default PlantingList;
