import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import { location } from 'history';
import Button from "../../../widgets/button";
import ConfirmationPopup from "../../../widgets/confirmationPopup";

interface Props {
  data: any;
  context: any;
}

const EditRow: React.FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const [modal, setModal] = useState<boolean>(false);

  const onViewButtonClicked = ({ id, districtId }: any) => {
    history.push(`/pincode-settings/${districtId}/${id}`);
  };

  const onEditButtonClicked = () => {
    props.context.componentParent.onEditButtonClicked(props.data);
  };

  return (
    <>
      <ConfirmationPopup
        message={`Are you sure to Delete?`}
        isOpen={modal}
        confirmedEvent={() => {
          props.context.componentParent.onChangeStatusToDelete(props.data);
          setModal(!modal);
        }}
        toggle={() => {
          setModal(!modal);
        }}
      ></ConfirmationPopup>

      <Button
        label="View Pincodes"
        className="ml-2 btn btn-outline-secondary"
        onClick={() => onViewButtonClicked(props.data)}
      />

      <button
        className="Action_btn"
        title="Edit"
        style={{ marginLeft: "16px" }}
        onClick={() => onEditButtonClicked()}
      >
        <i className="bi bi-pencil-square"></i>
      </button>

      <button
        className="Action_btn"
        title="Delete"
        style={{ marginLeft: "16px" }}
        onClick={() => {
          setModal(!modal);
        }}
      >
        <i className="bi bi-trash"></i>
      </button>
    </>
  );
};

EditRow.defaultProps = {};

export default EditRow;
