import React, { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { commonService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import Pagination from "../../../widgets/pagination";

type UserProjectStatsProps = {
  projectId: string;
  userId: string | undefined;
};

type UserProjectStatsState = {
  gridApi: any;
  tableRecords: any[];
  context: any;
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalElements: number;
  };
};

export class UserProjectStats extends Component<
  UserProjectStatsProps,
  UserProjectStatsState
> {
  constructor(props: UserProjectStatsProps) {
    super(props);
    this.state = {
      gridApi: "",
      tableRecords: [],
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllStats(data.selected, pageSize);
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getAllStats();
    }
  }

  getAllStats = (page: number = 1, pageSize: number = 10) => {
    if (this.props.userId) {
      try {
        commonService
          .getAllUserPlantStats(this.props?.userId, page, pageSize)
          .subscribe(
            (response: any) => {
              try {
                if (response.status && response.statusCode === 200) {
                  if (
                    Array.isArray(response.content) &&
                    response.content?.length > 0
                  ) {
                    let rowData: any = response.content.map((element: any) => ({
                      projectName: element.name,
                      plantedCount: element.total_planted_count,
                      plannedCount: element.project_plan,
                      liveCount: element.live_plants_count,
                      deadCount: element.dead_plants_count,
                    }));
                    this.state.gridApi.setRowData(rowData);
                    this.setState({
                      pagination: {
                        pageNumber: response.page || 1,
                        pageSize: response.size || 10,
                        totalPages: response.totalPages || 1,
                        totalElements: response.totalElements || 10,
                      },
                    });
                  }
                } else {
                  this.state.gridApi.setRowData([]);
                  showToaster(toasterTypes.ERROR, response.message);
                }
              } catch (error) {
                console.log(error?.message);
              }
            },
            (error: any) => {
              console.log(error);
              showToaster(toasterTypes.ERROR, error.message);
            }
          );
      } catch (error) {
        console.log(error?.message);
      }
    }
  };

  onGridReady = (params: any) => {
    this.getAllStats();
    this.setState({
      gridApi: params.api,
    });
  };

  render() {
    const { pagination } = this.state;
    return (
      <div className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          onGridReady={this.onGridReady}
          context={this.state.context}
          rowData={this.state.tableRecords}
          pagination={false}
          paginationPageSize={10}
          cacheBlockSize={10}
          enableBrowserTooltips={true}
          suppressDragLeaveHidesColumns={true}
        >
          <AgGridColumn
            field="projectName"
            sortable={true}
            filter={true}
            tooltipField="projectName"
            suppressMovable={true}
          />

          <AgGridColumn
            field="plannedCount"
            sortable={true}
            filter={true}
            type="rightAligned"
            tooltipField="plannedCount"
            suppressMovable={true}
          />

          <AgGridColumn
            field="plantedCount"
            sortable={true}
            filter={true}
            type="rightAligned"
            tooltipField="plannedCount"
            suppressMovable={true}
          />

          <AgGridColumn
            field="liveCount"
            sortable={true}
            filter={true}
            type="rightAligned"
            tooltipField="plannedCount"
            suppressMovable={true}
          />

          <AgGridColumn
            field="deadCount"
            sortable={true}
            filter={true}
            type="rightAligned"
            tooltipField="plannedCount"
            suppressMovable={true}
          />
        </AgGridReact>
        <div className="col-md-12 mt-2">
          <Pagination
            page={pagination.pageNumber}
            pageSize={pagination.pageSize}
            handlePageChange={this.handlePageClick}
            totalPage={pagination.totalPages}
          />
        </div>
      </div>
    );
  }
}
