import { userRoles } from "./roles";

const constants = {
  app: {
    baseURL: "https://uat.api.vruksha.alpha-03.trizentinc.com",
    renderFile:
      "https://uat.api.vruksha.alpha-03.trizentinc.com/files/download",
  },
};

export { constants, userRoles };
