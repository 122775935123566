import React, { Component } from "react";
import { showToaster, toasterTypes } from "../../widgets";
import { plantService } from "../../service";
import { UserHelper } from "../../utils";
import { constants } from "../../constants";
import { UncontrolledCarousel, UncontrolledTooltip } from "reactstrap";
import { photoViewProps, photoViewState } from "./interface";
import moment from "moment";
import Pagination from "../../widgets/pagination";

class PhotoView extends Component<photoViewProps, photoViewState> {
  constructor(props: photoViewProps) {
    super(props);
    this.state = {
      userData: "",
      selectedFile: [],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  componentDidMount() {
    const userDetails = UserHelper.getUserDetails();
    this.setState({ userData: userDetails });
    this.getAllPlantList();
  }
  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllPlantList(data.selected, pageSize);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  getAllPlantList = (pageSelected: number = 1, pageSize: number = 10) => {
    try {
      plantService
        .getAllPlantList(
          0,
          0,
          0,
          pageSelected,
          pageSize,
          this.props.match.params.userId
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = [];

                  response.content.forEach((element: any) => {
                    let date = moment(element.date_of_planting).format(
                      "DD-MM-YYYY"
                    );
                    let image: any = element.images?.map(
                      (element: any, index: number) => ({
                        key: index,
                        src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                      })
                    );
                    rowData.push({ plantedDate: date, dsplayimage: image });
                  });
                  this.setState({ selectedFile: rowData });
                  this.setState({
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  render() {
    const { pagination } = this.state;
    return (
      <main id="main">
        <section id="MyProfile" className="MyProfile">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-md-11 col-sm-11"></div>

              <div className="col-md-1 col-sm-1">
                <div
                  onClick={this.goBack}
                  className="text-decoration-none text-dark"
                >
                  <i className="fa fa-times float-right" id="close"></i>
                </div>
                <UncontrolledTooltip target={`close`}>
                  Close
                </UncontrolledTooltip>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <div className="My_Plantings_PhotoViewBox_head">
                  <h3 className="heading-secondary">
                    My Plantings - Photo View
                  </h3>
                </div>
                <div className="My_Plantings_PhotoViewBox_Body">
                  <ul>
                    {this.state.selectedFile?.map(
                      (element: any, index: number) => (
                        <li key={index}>
                          <div className="My_Plantings_PhotoView">
                            {element.dsplayimage.length === 1 ? (
                              <img
                                className="object_fit"
                                src={element.dsplayimage[0].src}
                                alt="project img"
                              />
                            ) : (
                              ""
                            )}
                            {element.dsplayimage.length > 1 ? (
                              <UncontrolledCarousel
                                controls={true}
                                indicators={false}
                                items={element.dsplayimage}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <h6 className="mt-2 text-center  form-label">
                            Planted On :{element.plantedDate}
                          </h6>

                          <div className="ShareWith_box">
                            <h6 className="m-0  form-label">Share With</h6>
                            <i className="bi bi-facebook"></i>
                          </div>
                        </li>
                      )
                    )}

                    <div className="col-md-12 mt-2 ">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={this.handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default PhotoView;
