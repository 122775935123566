import React, { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import Button from "../../../widgets/button";
import RoleChangeRow from "./partials/RoleChangeRow";
import { StatusRow } from "./partials/StatusRow";
import ResetPassword from "./partials/ResetPassword";
import { showToaster, toasterTypes } from "../../../widgets";
import { authenticationService, projectService } from "../../../service";
import { UserHelper } from "../../../utils";
import { listUsersProps, listUsersState } from "./interface";
import Pagination from "../../../widgets/pagination";
import Tablefeature from "../../../widgets/tableFeature";
import AssignProject from "./partials/assign-project";
import { userRoles } from "../../../constants";
import AddUser from "../add-user";
import { EditRow } from "./partials/EditRow";

class ListUsers extends Component<listUsersProps, listUsersState> {
  constructor(props: listUsersProps) {
    super(props);
    this.state = {
      tableRecords: [],
      projects: [],
      roleOptions: [],
      modal: false,
      gridApi: "",
      stateOptions: [],
      gridColumnApi: "",
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  componentDidMount() {
    this.getUserRole();
    this.getAllState();
    this.getProjectList();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getRowData();
      this.getProjectList();
    }
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getRowData(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getRowData(1, 10, e.target.value);
  };

  getRowData = (page: number = 1, pageSize: number = 10, key: string = "") => {
    try {
      let rowData: any = [];
      if (
        UserHelper.getUserDetails()?.user_role_id ===
          (userRoles.DISTRICT_ADMIN as any) ||
        UserHelper.getUserDetails()?.user_role_id ===
          (userRoles.STATE_ADMIN as any)
      ) {
        authenticationService
          .getAllVolunteersList(page, pageSize, key)
          .subscribe(
            (response: any) => {
              if (response.status && response.statusCode === 200) {
                if (response.content && Array.isArray(response.content)) {
                  rowData = response.content.map((element: any) => ({
                    id: element.id,
                    FirstName: element.first_name,
                    LastName: element.last_name,
                    Username : element.user_name,
                    MobileNumber: element.mobile_no_1,
                    Email: element.email_id,
                    Address: element.address,
                    Remark : element.remark,
                    Pincode: element.pincodes_name,
                    District: element.district_name,
                    State: element.state_name,
                    StatusData: element.status,
                    roleId: element.user_role_id,
                    roleName: element.user_role_name,
                    selectedProject: element?.projects,
                  }));
                  this.setState({ tableRecords: rowData });
                  this.setState({
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            },
            (error: any) => {
              console.log(error);
              showToaster(toasterTypes.ERROR, error.message);
            }
          );
      } else {
        authenticationService.getAllUsersList(page, pageSize, key).subscribe(
          (response: any) => {
            if (response.status && response.statusCode === 200) {
              if (response.content && Array.isArray(response.content)) {
                rowData = response.content.map((element: any) => ({
                  id: element.id,
                  FirstName: element.first_name,
                  LastName: element.last_name,
                  Username : element.user_name,
                  MobileNumber: element.mobile_no_1,
                  Email: element.email_id,
                  Address: element.address,
                  Remark : element.remark,
                  Pincode: element.pincodes_name,
                  District: element.district_name,
                  State: element.state_name,
                  StatusData: element.status,
                  roleId: element.user_role_id,
                  roleName: element.user_role_name,
                  selectedProject: element?.projects,
                }));
                this.setState({ tableRecords: rowData });
                this.setState({
                  pagination: {
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  },
                });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
      }

      this.setState({ tableRecords: rowData });
    } catch (error) {
      console.log(error?.message);
    }
  };

  getProjectList = () => {
    try {
      projectService.getAllProjectListSelect().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            try {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                let rowData: any = [];
                response.content.forEach((element: any) => {
                  if (element.child_project.length > 0) {
                    let options: any = [];
                    element.child_project.forEach((subElement: any) => {
                      options.push({
                        label: subElement.name,
                        value: subElement.id,
                      });
                    });
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options,
                    });
                  } else {
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options: [],
                    });
                  }
                });
                this.setState({ projects: rowData });
              }
            } catch (error) {
              console.log(error?.message);
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  getAllState = () => {
    try {
      authenticationService.getStateList().subscribe({
        next: (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: { value: number; label: string; count: string }[] =
                  response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                    count: "",
                  }));
                this.setState({ stateOptions: rowData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error);
          }
        },
        error: (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  getUserRole = () => {
    try {
      authenticationService.getUsersRole().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              const userRole = UserHelper.getUserDetails()?.user_role_id;
              console.log(userRole);
              let rowData: any = [];
              response.data.forEach((element: any) => {
                if (userRole && userRole < element.id) {
                  rowData.push({
                    label: element.name,
                    value: element.id,
                  });
                }

                if (
                  userRole === (userRoles.SUPER_ADMIN as any) &&
                  userRole === element.id
                ) {
                  rowData.push({
                    label: element.name,
                    value: element.id,
                  });
                }
              });
              this.setState({ roleOptions: rowData }, () => {
                this.getRowData();
              });
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  onGridReady = (params: any) => {
    this.setState({
      gridApi: params.api,
      gridColumnApi: params.columnApi,
    });
  };

  autoSizeAllColumns = (skipHeader: boolean) => {
    let allColumnIds: any[] = [];
    this.state.gridColumnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    this.state.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  changeStatusHandler = (data: any) => {
    try {
      const userData = {
        user_id: data.id,
        status: data.StatusData === "active" ? "inactive" : "active",
      };
      authenticationService.userChangeStatus(userData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.getRowData();
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  render() {
    const {
      handlePageClick,
      state: { pagination, stateOptions, roleOptions, projects },
    } = this;

    return (
      <main id="main">
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center justify-content-start mb-4 ">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    View Users List
                  </h3>

                  <Button
                    label="Create"
                    className="ml-2 btn btn-outline-secondary"
                    onClick={this.toggle}
                  />
                </div>
                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />
                <div className="ag-theme-alpine">
                  <AgGridReact
                    domLayout="autoHeight"
                    context={this.state.context}
                    onGridReady={this.onGridReady}
                    rowData={this.state.tableRecords}
                    enableBrowserTooltips={true}
                    defaultColDef={{
                      resizable: true,
                    }}
                    frameworkComponents={{
                      roleColumn: RoleChangeRow,
                      editColumn: EditRow,
                      statusColumn: StatusRow,
                      pswdColumn: ResetPassword,
                      assignProject: AssignProject,
                    }}
                    suppressDragLeaveHidesColumns={true}
                  >
                    <AgGridColumn
                      field="FirstName"
                      sortable={true}
                      filter={true}
                      tooltipField="FirstName"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="LastName"
                      sortable={true}
                      filter={true}
                      tooltipField="LastName"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Username"
                      sortable={true}
                      filter={true}
                      tooltipField="Username"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="MobileNumber"
                      sortable={true}
                      filter={true}
                      tooltipField="MobileNumber"
                      suppressMovable={true}
                    />

                    <AgGridColumn
                      field="roleName"
                      sortable={true}
                      filter={true}
                      tooltipField="roleName"
                      suppressMovable={true}
                    />

                    {/* <AgGridColumn
                      field="Role"
                      minWidth={175}
                      cellRenderer="roleColumn"
                      cellRendererParams={{
                        roleOptions: this.state.roleOptions,
                      }}
                      suppressMovable={true}
                    /> */}
                    <AgGridColumn
                      field="Status"
                      width={100}
                      cellRenderer="statusColumn"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Reset Password"
                      width={200}
                      tooltipField="Reset Password"
                      cellRenderer="pswdColumn"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Projects"
                      width={200}
                      tooltipField="Assign Projects"
                      cellRenderer="assignProject"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Email"
                      sortable={true}
                      filter={true}
                      tooltipField="Email"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Address"
                      sortable={true}
                      filter={true}
                      tooltipField="Address"
                      wrapText={true}
                      autoHeight={true}
                      width={500}
                      suppressMovable={true}
                    />
                     <AgGridColumn
                      field="Remark"
                      sortable={true}
                      filter={true}
                      tooltipField="Remark"
                      wrapText={true}
                      autoHeight={true}
                      width={200}
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Pincode"
                      headerName="Pin Code"
                      sortable={true}
                      filter={true}
                      tooltipField="Pin Code"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="District"
                      headerName="Area"
                      sortable={true}
                      filter={true}
                      tooltipField="District"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="State"
                      headerName="Category"
                      sortable={true}
                      filter={true}
                      tooltipField="State"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Action"
                      minWidth={100}
                      cellRenderer="editColumn"
                      cellRendererParams={{
                        roleOptions,
                        stateOptions,
                        projects,
                      }}
                      flex={1}
                      suppressMovable={true}
                    />
                  </AgGridReact>
                </div>
                <div className="col-md-12 mt-2">
                  <Pagination
                    page={pagination.pageNumber}
                    pageSize={pagination.pageSize}
                    handlePageChange={handlePageClick}
                    totalPage={pagination.totalPages}
                  />
                </div>
                <AddUser
                  modal={this.state.modal}
                  toggle={this.toggle}
                  roleOptions={roleOptions}
                  stateOptions={stateOptions}
                  projects={projects}
                  refresh={() => {
                    this.getRowData();
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ListUsers;
