import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UncontrolledCarousel } from "reactstrap";
import { constants } from "../../../../../constants";
import { plantService } from "../../../../../service";
import { showToaster, toasterTypes } from "../../../../../widgets";
import Pagination from "../../../../../widgets/pagination";

interface RecentPlantingsProps {
  selectedFile: any;
  userId: string;
}
const RecentPlantings: React.FunctionComponent<RecentPlantingsProps> = (
  props
) => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalElements: 0,
  });

  const [plantings, setplantings] = useState([]);

  useEffect(() => {
    getAllPlantList();
  }, []);

  const handlePageClick = (
    data: { selected: number },
    pageSize: number = pagination.pageSize
  ) => {
    getAllPlantList(data.selected, pageSize);
  };

  const getAllPlantList = (pageSelected: number = 1, pageSize: number = 10) => {
    try {
      plantService
        .getAllPlantList(0, 0, 0, pageSelected, pageSize, props.userId)
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = [];

                  response.content.forEach((element: any) => {
                    let date = element.date_of_planting;
                    let place = element.pin_code;
                    let totalPlants = element.total_plants_count;
                    let totalLive = element.live_plants_count;
                    let totalDead = element.dead_plants_count;

                    let image: any = [];
                    if (
                      Array.isArray(element.images) &&
                      element.images.length > 0
                    ) {
                      element.images?.forEach((element: any, index: number) => {
                        image.push({
                          key: index,
                          src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                        });
                      });
                    }
                    rowData.push({
                      plantedDate: moment(date).format("DD-MM-YYYY"),
                      dsplayimage: image,
                      pin_code: place,
                      totalPlantsCount: totalPlants,
                      totalLiveCount: totalLive,
                      totalDeadCount: totalDead,
                    });
                  });
                  setplantings(rowData);
                  setPagination({
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <div>
      {plantings.length > 0 && (
        <div className="recentPlantings">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h6 className="m-0">All Plantings</h6>
            <div className=" text-right mr-3"></div>
          </div>
          <div className="row">
            {plantings.map((tree: any, index: number) => (
              <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                <div
                  className="My_Plantings_PhotoView"
                  style={{ width: "150px", marginTop: "10px", height: "140px" }}
                >
                  {/* {Array.isArray(tree.dsplayimage) &&
                  tree.dsplayimage?.length > 0 ? (
                    <img
                      src={`${constants.app.baseURL}/files/download?file_id=${tree.dsplayimage[0].id}`}
                      alt="plant"
                    />
                  ) : (
                    ""
                  )} */}

                  {tree.dsplayimage.length === 1 ? (
                    <img src={tree.dsplayimage[0].src} alt="plant" />
                  ) : (
                    ""
                  )}
                  {tree.dsplayimage.length > 1 ? (
                    <UncontrolledCarousel
                      controls={true}
                      indicators={false}
                      items={tree.dsplayimage}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
                  {/* <h6>{tree.name}</h6> */}
                  <div className="row">
                    <div className="col-sm-5">
                      <p className="m-0 pindate">Planted Date</p>
                    </div>
                    <div className="col-sm-7 text-right">
                      <p className="m-0 pindate">{tree.plantedDate}</p>
                    </div>
                  </div>
                </div>
                <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
                  {/* <h6>{tree.name}</h6> */}
                  <div className="row">
                    <div className="col-sm-5">
                      <p className="m-0 pindate">Total</p>
                    </div>
                    <div className="col-sm-7 text-right">
                      <p className="m-0 pindate">{tree.totalPlantsCount}</p>
                    </div>
                  </div>
                </div>
                <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
                  {/* <h6>{tree.name}</h6> */}
                  <div className="row">
                    <div className="col-sm-5">
                      <p className="m-0 pindate">Live</p>
                    </div>
                    <div className="col-sm-7 text-right">
                      <p className="m-0 pindate">{tree.totalLiveCount}</p>
                    </div>
                  </div>
                </div>
                <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
                  {/* <h6>{tree.name}</h6> */}
                  <div className="row">
                    <div className="col-sm-5">
                      <p className="m-0 pindate">Dead</p>
                    </div>
                    <div className="col-sm-7 text-right">
                      <p className="m-0 pindate">{tree.totalDeadCount}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-md-12 mt-2 ">
              <Pagination
                page={pagination.pageNumber}
                pageSize={pagination.pageSize}
                handlePageChange={handlePageClick}
                totalPage={pagination.totalPages}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecentPlantings;
