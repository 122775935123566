import React, { useState } from "react";
import ConfirmationPopup from "../../../widgets/confirmationPopup";

interface Props {
  data: any;
  context: any;
}

const EditRow: React.FunctionComponent<Props> = (props) => {
  const [modal, setModal] = useState<boolean>(false);
  const onEditButtonClicked = () => {
    props.context.componentParent.onEditButtonClicked(props.data);
  };

  return (
    <>
      <ConfirmationPopup
        message={`Are you sure to Delete?`}
        isOpen={modal}
        confirmedEvent={() => {
          props.context.componentParent.onChangeStatusToDelete(props.data);
          setModal(!modal);
        }}
        toggle={() => {
          setModal(!modal);
        }}
      ></ConfirmationPopup>

      <button
        className="Action_btn"
        title="Edit"
        onClick={() => onEditButtonClicked()}
      >
        <i className="bi bi-pencil-square"></i>
      </button>

      <button
        className="Action_btn"
        title="Delete"
        style={{ marginLeft: "16px" }}
        onClick={() => {
          setModal(!modal);
        }}
      >
        <i className="bi bi-trash"></i>
      </button>
    </>
  );
};

EditRow.defaultProps = {};

export default EditRow;
