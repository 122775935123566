import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../../widgets/button";
import FarmerView from "../farmer-view";

function FarmerActionColumn(props: any) {
  const [modal, setModal] = useState(false);

  const closeBtn = (
    <button
      className="close"
      title="Close"
      onClick={() => {
        setModal(!modal);
      }}
    >
      &times;
    </button>
  );

  const viewFamerDetails = () => {
    setModal(true);
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
      >
        <ModalHeader
          className="modal_Bg"
          close={closeBtn}
          toggle={() => {
            setModal(!modal);
          }}
        >
          {" "}
          <div className="modal_title"> Farmer Details</div>
        </ModalHeader>
        <ModalBody style={{ overflowY: "auto", height: "500px" }}>
          <FarmerView userId={props.data.id} projectId={props.projectId} />
        </ModalBody>
      </Modal>

      <Button
        label="View Details"
        className="ml-2 btn btn-outline-secondary"
        onClick={() => viewFamerDetails()}
      />
    </>
  );
}

export default FarmerActionColumn;
