import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledCarousel } from "reactstrap";
import { constants } from "../../../../constants";
import Button from "../../../../widgets/button";

interface RecentPlantingsProps {
  selectedFile: any;
}
const RecentPlantings: React.FunctionComponent<RecentPlantingsProps> = (
  props
) => {
  return (
    <div className="recentPlantings">
      <div className="d-flex align-items-center">
        <div className="col-md-7 d-flex align-items-center">
          <h6 className="m-0">Recent Plantings</h6>
          <Link to="/createPlant">
            <Button
              label="Create New"
              className="btn-sm ml-2 btn btn-outline-secondary"
            />
          </Link>
        </div>
        <div className="col-md-5 text-right mt-1">
          <Link to="/photoView" className=" create-link text-theme p2-2 ">
            View All Plantings
          </Link>
        </div>
      </div>
      <div className="row">
        {props.selectedFile.slice(0, 15).map((tree: any, index: number) => (
          <div className="col-md-3" key={index}>
            <div
              className="My_Plantings_PhotoView"
              style={{ width: "150px", marginTop: "10px", height: "140px" }}
            >
              {tree.dsplayimage.length === 1 ? (
                <img src={tree.dsplayimage[0].src} alt="plant" />
              ) : (
                ""
              )}

              {tree.dsplayimage.length > 1 ? (
                <UncontrolledCarousel
                  controls={true}
                  indicators={false}
                  items={tree.dsplayimage}
                />
              ) : (
                ""
              )}
            </div>

            <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
              {/* <h6>{tree.name}</h6> */}
              <div className="row">
                <div className="col-sm-5">
                  <p className="m-0 pindate">Planted Date</p>
                </div>
                <div className="col-sm-7 text-right">
                  <p className="m-0 pindate">{tree.plantedDate}</p>
                </div>
              </div>
            </div>
            <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
              {/* <h6>{tree.name}</h6> */}
              <div className="row">
                <div className="col-sm-5">
                  <p className="m-0 pindate">Total</p>
                </div>
                <div className="col-sm-7 text-right">
                  <p className="m-0 pindate">{tree.totalPlantsCount}</p>
                </div>
              </div>
            </div>
            <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
              {/* <h6>{tree.name}</h6> */}
              <div className="row">
                <div className="col-sm-5">
                  <p className="m-0 pindate">Live</p>
                </div>
                <div className="col-sm-7 text-right">
                  <p className="m-0 pindate">{tree.totalLiveCount}</p>
                </div>
              </div>
            </div>
            <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
              {/* <h6>{tree.name}</h6> */}
              <div className="row">
                <div className="col-sm-5">
                  <p className="m-0 pindate">Dead</p>
                </div>
                <div className="col-sm-7 text-right">
                  <p className="m-0 pindate">{tree.totalDeadCount}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentPlantings;
