import Select, { components } from "react-select";

const renderNestedOption = (props: any, label: any, nestedOptions: any) => {
  const { innerProps, selectOption } = props;

  return (
    <div className="nested-optgroup">
      <div
        style={{
          color: "grey",
          paddingLeft: "10px",
        }}
      >
        {label}
      </div>
      {nestedOptions.map((nestedOption: any, i: any) => {
        if (nestedOption.options) {
          return renderNestedOption(
            props,
            nestedOption.label,
            nestedOption.options
          );
        }
        const nestedInnerProps = {
          ...innerProps,
          onClick: () => selectOption(nestedOption),
        };
        return (
          <components.Option {...props} key={i} innerProps={nestedInnerProps}>
            {nestedOption.label}
          </components.Option>
        );
      })}
    </div>
  );
};

const Option = (props: any) => {
  const { children, data } = props;
  const nestedOptions = data.options;
  if (nestedOptions) {
    const label = data.label;
    return renderNestedOption(props, label, nestedOptions);
  }
  return <components.Option {...props}>{children}</components.Option>;
};

export default function Nested(props: any) {
  return (
    <Select
      components={{
        Option,
      }}
      isClearable
      isSearchable
      formatGroupLabel={(value) => {
        return value.label;
      }}
      formatOptionLabel={(value) => {
        return value.label;
      }}
      getOptionValue={(value) => {
        return value.label;
      }}
      onChange={(value) => {
        props.onChange(value);
      }}
      options={props.options}
      placeholder="Select Project"
      className="nested-select"
      value={props.value}
      isDisabled={props.disabled}
    />
  );
}
