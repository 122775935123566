import * as React from "react";
import { Component } from "react";
import Button from "../../widgets/button";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import { Link, Redirect } from "react-router-dom";
import { showToaster, toasterTypes } from "../../widgets";
import { authenticationService } from "../../service";
import { loginResponse } from "../../model";
import { UserHelper, Validation } from "../../utils";
import { userRoles } from "../../constants";
class TermsAndCondition extends Component {
  componentDidMount() {
    // clear localstorage if user not logged
    if (Validation.isUserLogged()) {
      this.setState({ isAuthenticated: true });
    } else {
      localStorage.clear();
    }
  }

  handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const state: any = { ...this.state };
    state[name] = value;
    this.setState(state);
  };

  responsiveMenu = () => {
    let navbarToggle = this.select(".mobile-nav-toggle");
    navbarToggle.classList.toggle("bi-list");
    navbarToggle.classList.toggle("bi-x");
    this.select("#navbar").classList.toggle("navbar-mobile");
  };

  closeMobileMenuNav = () => {
    if (this.select("#navbar").classList.contains("navbar-mobile text-black")) {
      let navbarToggle = this.select(".mobile-nav-toggle text-black");
      navbarToggle.classList.toggle("bi-list");
      navbarToggle.classList.toggle("bi-x");
      this.select("#navbar").classList.remove("navbar-mobile");
    }
  };

  select = (el: any, all = false) => {
    el = el.trim();
    return document.querySelector(el);
  };

  render() {
    return (
      <div>
        <main>
          <header id="homeheaderNew" className="d-flex align-items-center">
            <nav id="navbar" className="navbar">
              <div className="homeheader-items">
                <ul>
                  <li className="ml-2 c-hover d-flex align-items-center ">
                    <Link to="/" className="text-white">
                      Home
                    </Link>
                  </li>
                  <li className="ml-2 c-hover d-flex align-items-center">
                    <Link
                      to="/aboutUs"
                      className="text-white"
                      onClick={() => {
                        this.closeMobileMenuNav();
                      }}
                    >
                      About Us
                    </Link>
                  </li>
                  {/* <li className="ml-2 c-hover d-flex align-items-center">
                    <Link
                      to="/testimonials"
                      className="text-white"
                      onClick={() => {
                        this.closeMobileMenuNav();
                      }}
                    >
                      Testimonials
                    </Link>
                  </li> */}

                  <li className="ml-2 c-hover d-flex align-items-center">
                    <Link
                      to="/"
                      className="text-white"
                      onClick={() => {
                        this.closeMobileMenuNav();
                      }}
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="ml-2 c-hover d-flex align-items-center">
                    <Link
                      to="/login"
                      className="text-white"
                      // style={{ color: "white" }}
                      onClick={() => {
                        this.closeMobileMenuNav();
                      }}
                    >
                      Sign In
                    </Link>
                  </li>
                </ul>
                <i
                  className="bi bi-list mobile-nav-toggle"
                  onClick={this.responsiveMenu}
                ></i>
              </div>
            </nav>
          </header>
          <section className="bgprivacy">
            <div className="testhead">Terms of use</div>
            <div className="px-4 mt-5">
              <p className="termsText">
                The Art of Living Foundation owns and retains the copyright and
                all other rights in the contents of this website, including all
                the text, pictures, photographs, graphics, marks and logos
                displayed on this site.
              </p>
              <p className="termsText">
                You are not entitled to reproduce any contents on the site for
                sale, distribution or commercial gain. No part of this site may
                be incorporated in any other work, publication or site in any
                form/format whatsoever. You shall also not be entitled to modify
                and/or reuse any of the contents on this site for any purpose
                whatsoever.
              </p>
              <p className="termsText">
                You may print copies of the information on this site for your
                personal use and/or store the files on your computer for
                personal use.
              </p>
              <p className="termsText">
                The information and contents on this site are for general
                purposes only and may not be relied upon for any specific
                purpose. The Art of Living Foundation makes no representation or
                warranty as regards the accuracy or completeness of any
                information and/or contents contained in this site.
              </p>
              <p className="termsText">
                We shall not be liable for the content of websites to which
                visitors of this website are referred. Links to such websites
                are provided only to complement information provided on this
                site.
              </p>
              <p className="termsText">
                Any personal information that you may provide on this site shall
                form part of the database of the Art of Living Foundation and
                may be used by it and/or by any of its affiliated / sister
                organizations and trusts only for the purpose of its/their
                activities and objects. We do not sell, trade in or commercially
                exploit any such personal information. We also do not share any
                such personal information with any third party other than our
                affiliated / sister organizations and trusts.
              </p>
              <p className="termsText">
                We reserve the right to suspend, discontinue or modify any part
                of this site without notice.
              </p>
            </div>
          </section>

          {/* <section className="bgfooter  ">
            <div className="container">
              <div className="corow">
                <div className="col-lg-3 col-md-6 col-xs-6 px-3 footer-info"> */}
                  {/* <div className="footerhead ">About Us</div> */}
                  {/* <img
                src={`${process.env.PUBLIC_URL}/assets/img/Vruksha_logo_white.png`}
                alt="logo"
              /> */}
                  {/* <p className="p mt-2 mb-2 text-black">
                    The Art of Living is a multi-faceted, non-profit
                    organization with a global presence. Founded in 1981 by a
                    world renowned humanitarian and spiritual leader, Gurudev
                    Sri Sri Ravi Shankar ji. */}
                    {/* {props.about_art_of_living} */}
                    {/* Operating in 156 countries, The Art of Living is a non-profit, educational and humanitarian organization founded in 1981 by the world-renowned humanitarian and spiritual teacher - Gurudev Sri Sri Ravi Shankar. All our programs are guided by Gurudev’s philosophy: “Unless we have a stress-free mind and a violence-free society, we cannot achieve world peace.” */}
                  {/* </p> */}
                {/* </div>
                <div className="col-lg-3 col-md-6 col-xs-6 px-3 footer-links">
                  <div>
                    <Link to="/privacyPolicy" className="footerhead ">
                      Privacy Policy
                    </Link>
                  </div>
                  <div>
                    <Link to="/termsAndCondition" className="footerhead ">
                      Terms & Conditions
                    </Link>
                  </div> */}
                  {/* <div className="footerhead ">Privacy & Policy</div> */}
                  {/* <img
                src={`${process.env.PUBLIC_URL}/assets/img/Vruksha_logo_white.png`}
                alt="logo"
              /> */}
                {/* </div>

                <div className="col-lg-2 col-md-4 col-xs-5 px-2 footer-links">
                  <div>
                    <Link to="/" className="footerhead ">
                      Home
                    </Link>
                  </div>
                  <div>
                    <Link to="/login" className="footerhead ">
                      Sign In
                    </Link>
                  </div>
                  <div>
                    <Link to="/signup" className="footerhead ">
                      Register
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-xs-5 px-2 footer-links">
                  <div>
                    <Link to="/aboutUs" className="footerhead ">
                      About Us
                    </Link>
                  </div> */}
                  {/* <div>
                    <Link to="/testimonials" className="footerhead ">
                      Testimonials
                    </Link>
                  </div> */}
                  {/* <div>
                    <Link to="/" className="footerhead ">
                      Projects
                    </Link>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-xs-5 px-2 footer-links">
                  <div className="footerhead"> Social Media</div>
                  <div className="d-flex align-items-center ">
                    <div className="social-links d-none d-md-block mt-half px-2">
                      <a
                        target={"_blank"}
                        href="https://twitter.com/ArtofLiving"
                        className="twitter"
                      >
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a
                        target={"_blank"}
                        href="https://facebook.com/ArtofLiving"
                        className="facebook px-1 "
                      >
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a
                        target={"_blank"}
                        href="https://instagram.com/artofliving"
                        className="instagram px-1"
                      >
                        <i className="bi bi-instagram"></i>
                      </a> */}
                      {/* <Link
                      to={siteData.linkedInAccountLink}
                      className="linkedin px-1"
                    >
                      <i className="bi bi-linkedin"></i>
                    </Link> */}
                    {/* </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <div className="footer-content2">
            <footer id="footer-new">
              <div className="container">
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="">
                        &copy; Copyright{" "}
                        <strong>
                          <span>Mission Green Earth</span>
                        </strong>
                        . All Rights Reserved
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div> */}
          <section className="bgfooter  ">
            <div className="container">
              <div className="corow">
                <div
                  className="col-lg-5 col-md-2 col-xs-6 px-3 footer-links"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <div>
                    <Link to="/privacyPolicy" className="footerhead ">
                      Privacy Policy
                    </Link>
                  </div>
                  <div>
                    <Link to="/termsAndCondition" className="footerhead ">
                      Terms & Conditions
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-3 col-xs-5 px-2 footer-links">
                  <div className="row">
                    <div>
                      <Link to="/" className="footerhead ">
                        Home
                      </Link>
                    </div>
                    <div>
                      <Link to="/login" className="footerhead ">
                        Sign In
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-xs-5 px-2 footer-links"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <div>
                    <Link to="/signup" className="footerhead ">
                      Register
                    </Link>
                  </div>
                  <div>
                    <Link to="/aboutUs" className="footerhead ">
                      About Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="footer-content2 ">
            <footer id="footer-new">
              <div className="">
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="footText">
                        &copy; Copyright{" "}
                        <strong>
                          <span>Mission Green Earth</span>
                        </strong>
                        . All Rights Reserved
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </main>
      </div>
    );
  }
}

export default TermsAndCondition;
