import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { constants, userRoles } from "../../../../../constants";
import { UserHelper } from "../../../../../utils";
interface userCardsProps {
  name?: string;
  email?: string;
  mobileNumber?: number;
  pincode?: number;
  photoId?: number;
  invites?: number;
  achivementData?: string;
  plantCount?: number;
  assignedQuantity?: number;
}

const defaultProps: userCardsProps = {
  name: " ",
  email: " ",
  mobileNumber: 0,
  pincode: 0,
  invites: 0,
  achivementData: "-",
  plantCount: 0,
  assignedQuantity: 0,
};

const UserCard: React.FunctionComponent<userCardsProps> = (
  props: userCardsProps
) => {
  const currentUser = UserHelper.getUserDetails();
  return (
    <div className="userCard mt-3">
      <div className="card">
        <div className="card-header">
          <Link to="/profile">
            <i
              className="bi bi-pencil editIcon"
              id="EditProfile"
              style={{ color: "#888" }}
            ></i>
          </Link>
          <UncontrolledTooltip target={`EditProfile`}>Edit</UncontrolledTooltip>
          <label title={props.name} className="card-header-name2 ">
            {props.name}
          </label>
          <br />
          <label className="card-header-desc2">Farmer</label>
        </div>

        <div className="card-user-img">
          {props.photoId != 0 ? (
            <img
              alt="user"
              src={`${constants.app.baseURL}/files/download?file_id=${props.photoId}`}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/user.png`}
              alt="profile "
            />
          )}
        </div>
        <div className="card">
          <div className="card-headers">
            <div className="row">
              <div className="col">
                <div className="">
                  <i className="bi bi-tree text-black"></i>
                </div>
                <div className=" mt-3 ">
                  <div className="text-black">
                    <div>{props.assignedQuantity}</div>
                    <div className="">Assigned</div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="">
                  <i className="bi bi-tree-fill text-black"></i>
                </div>
                <div className=" mt-3 ">
                  <div className="text-black">
                    <div>{props.plantCount}</div>
                    <div className="">Planted</div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="">
                  <i className="bi bi-person-badge text-black"></i>
                </div>
                <div className="mt-3 ">
                  <div>{props.achivementData}</div>
                  <div className="">Badges</div>
                </div>
              </div>

              <div className="col">
                <div className="">
                  <i className="bi b bi-share text-black"></i>
                </div>
                <div className=" mt-3  ">
                  <div className="text-black">
                    <div>{props.invites}</div>
                    <div className="">Invites</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {currentUser?.user_role_id !== (userRoles.EXTERNAL as any) && (
          <div className="card-footer">
            <div className="row no-gutters">
              <div className="col">
                <p className="card-footer-heading text-bold p-0">Email:</p>

                {/* <div className="col"> */}
                <p className=" m-0">{props.email}</p>
                {/* </div> */}
                {/* <div className="row mt-2"> */}
              </div>

              <div className="col">
                <p className="card-footer-heading text-bold p-0">Mob:</p>

                <p className="m-0">{props.mobileNumber}</p>
                {/* </div> */}
              </div>
              <div className="row mt-2">
                <div className="col">
                  <p className="card-footer-heading text-bold p-0">Pin Code:</p>
                </div>
              </div>
              <div className="col">
                <p className="m-0">{props.pincode}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

UserCard.defaultProps = defaultProps;
export { UserCard };
