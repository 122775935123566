import { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import EditRow from "./partials/EditRow";
import { showToaster, toasterTypes } from "../../widgets";
import { AreaSettingProps, AreaSettingState } from "./interface";
import Pagination from "../../widgets/pagination";
import Tablefeature from "../../widgets/tableFeature";
import Button from "../../widgets/button";
import AddEditAreaModal from "./_dialogues/add-edit-area";
import { CategoryService } from "../../service/category";
import StatusChange from "./partials/status-change";
import { Link } from "react-router-dom";

class AreaSetting extends Component<AreaSettingProps, AreaSettingState> {
  constructor(props: AreaSettingProps) {
    super(props);
    this.state = {
      editArea: null,
      stateName: "",
      tableRecords: [],
      dataTableKey: 0,
      pageSize: 10,
      page: 1,
      gridApi: "",
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
      areaModal: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.categoryId) {
      this.getAllRowData();
    } else {
      this.props.history.push("/category-settings");
    }
  }

  reFreshTable = () => {
    this.setState({ dataTableKey: this.state.dataTableKey + 1 });
  };

  getReactContainerClasses() {
    return ["custom-tooltip"];
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllRowData(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getAllRowData(1, 10, e.target.value);
  };

  getAllRowData = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      CategoryService.getAllAreas(
        page,
        pageSize,
        key,
        this.props.match.params.categoryId
      ).subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              this.setState({ stateName: response.state_name });
              if (response.data && Array.isArray(response.data)) {
                let rowData: any = [];
                response.data.forEach((element: any) => {
                  console.log(element);
                  rowData.push({
                    id: element.id,
                    areaName: element.name,
                    districtId: element.state_id,
                    status: element.status,
                  });
                });

                this.state.gridApi.setRowData(rowData);
                this.setState({
                  pagination: {
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  },
                });
              }
            } else {
              this.state.gridApi.setRowData([]);
              this.setState({
                pagination: {
                  pageNumber: response.page || 1,
                  pageSize: response.size || 10,
                  totalPages: response.totalPages || 1,
                  totalElements: response.totalElements || 10,
                },
              });
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error?.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onEditButtonClicked = (area: any) => {
    this.setState({ editArea: area });
    this.setState({ areaModal: true });
  };

  onChangeStatusToDelete = (data: any) => {
    try {
      const userData = {
        id: data.id,
        status: "deleted",
      };
      CategoryService.areaChangeStatus(userData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.getAllRowData();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onDeleteButtonClicked = (data: any, status: any) => {
    try {
      const userData = {
        id: data.id,
        status: status.value,
      };
      CategoryService.areaChangeStatus(userData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.getAllRowData();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.setState({
      gridApi: params.api,
    });
  };

  render() {
    const {
      state: { pagination },
      handlePageClick,
    } = this;

    return (
      <main id="main">
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <li className="forget-pass" style={{ marginBottom: "15px" }}>
              <Link to="/category-settings" style={{ fontSize: "13px" }}>
                {" "}
                Category Setting{" "}
              </Link>{" "}
              <span style={{ fontSize: "13px" }}>/</span>
              <Link
                to={`/area-settings/${this.props.match.params.categoryId}`}
                style={{ fontSize: "13px" }}
              >
                {" "}
                Area Setting{" "}
              </Link>
            </li>
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex align-items-center justify-content-between mb-4">
                <div className=" d-flex align-items-center justify-content-start ">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    Area Settings - {this.state.stateName}
                  </h3>
                  <Button
                    label="Add Area"
                    className="ml-2 btn btn-outline-secondary"
                    onClick={() => this.setState({ areaModal: true })}
                  />
                </div>
              </div>

              <div className="col-lg-12 d-flex flex-column justify-content-center">
                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />
                <AddEditAreaModal
                  modal={this.state.areaModal}
                  onSubmitSuccess={this.getAllRowData}
                  setAreaModel={(state) =>
                    this.setState({ areaModal: state, editArea: null })
                  }
                  editArea={this.state.editArea}
                  categoryId={this.props.match.params.categoryId}
                />
                <div className="grid">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      key={this.state.dataTableKey}
                      domLayout={"autoHeight"}
                      cacheBlockSize={10}
                      context={this.state.context}
                      rowData={this.state.tableRecords}
                      enableCellChangeFlash={true}
                      onGridReady={this.onGridReady}
                      enableBrowserTooltips={true}
                      frameworkComponents={{
                        editColumn: EditRow,
                        statusColumn: StatusChange,
                      }}
                      suppressDragLeaveHidesColumns={true}
                    >
                      <AgGridColumn
                        field="areaName"
                        sortable={true}
                        flex={2}
                        filter={true}
                        tooltipField="Area Name"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="Status"
                        flex={1}
                        suppressMovable={true}
                        cellRenderer="statusColumn"
                        cellRendererParams={{
                          reFreshTable: this.state.dataTableKey,
                        }}
                      />

                      <AgGridColumn
                        field="Action"
                        flex={1}
                        suppressMovable={true}
                        cellRenderer="editColumn"
                        cellRendererParams={{
                          reFreshTable: this.state.dataTableKey,
                        }}
                      />
                    </AgGridReact>
                    <div className="col-md-12 mt-2">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default AreaSetting;
