import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AddDistributionPopup } from "../../components/AddDistributionPopup";
import { showToaster, toasterTypes } from "../../../../widgets";
import { authenticationService } from "../../../../service";
interface editRowProps {
  context: any;
  data: any;
  projectId: string;
  totalQuantity: string;
  totalDistributed: string;
  districtId?: string;
}
interface editRowStateProps {
  modal: boolean;
  listDistributionPage: boolean;
  usersOptions: any;
}
export class DistributionRow extends Component<
  editRowProps,
  editRowStateProps
> {
  constructor(props: editRowProps) {
    super(props);
    this.state = {
      modal: false,
      listDistributionPage: false,
      usersOptions: [],
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    if (!this.state.modal) {
      this.getAllUserData();
    }
  };

  getAllUserData = () => {
    try {
      authenticationService
        .getAllUsersListForProjectVolunteers(
          this.props.projectId,
          this.props.districtId
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = response.content.map((element: any) => ({
                    value: element.id,
                    label: element.first_name,
                  }));
                  this.setState({ usersOptions: rowData });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  openListDistribution = () => {
    this.setState({ listDistributionPage: true });
  };
  refresh = () => {
    this.props.context.componentParent.getAllSaplingList();
    this.props.context.componentParent.getPincodeLevellDistribution(
      this.props.districtId || ""
    );
  };
  render() {
    if (this.state.listDistributionPage && !this.props.districtId) {
      return (
        <Redirect
          to={`/listDistribution/${this.props.data.PincodeId}/${
            this.props.districtId || ""
          }`}
        />
      );
    } else if (this.state.listDistributionPage && this.props.districtId) {
      return (
        <Redirect
          to={`/state-pincode-distribution/${this.props.data.PincodeId}/${
            this.props.districtId || ""
          }`}
        />
      );
    }
    return (
      <>
        <AddDistributionPopup
          totalQuantity={{
            totalDistributed: this.props.totalDistributed,
            totalDispatched: this.props.totalQuantity,
          }}
          districtId={this.props.districtId}
          usersOptions={this.state.usersOptions}
          modal={this.state.modal}
          toggle={this.toggle}
          refresh={this.refresh}
          pincodeId={this.props.data.PincodeId}
        />
        <div className="cursor_point d-flex justify-content-between">
          <span
            className="text-right align_count"
            onClick={this.openListDistribution}
          >
            {this.props.data.TotalDistributedCount}
          </span>

          <div>
            <button
              className="btn-sm  btn btn-outline-secondary addPopupButton"
              title="Add Distribution"
              onClick={this.toggle}
            >
              Add Distribution
            </button>
          </div>
        </div>
      </>
    );
  }
}
