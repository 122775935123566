import React, { Component } from "react";
import { showToaster, toasterTypes } from "../../../widgets";
import { plantService, projectService, commonService } from "../../../service";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import { Validation } from "../../../utils";
import { constants } from "../../../constants";
import { UncontrolledCarousel } from "reactstrap";
import { plantingsProps, plantingsState } from "./interface";
import moment from "moment";
import Pagination from "../../../widgets/pagination";

class Plantings extends Component<plantingsProps, plantingsState> {
  constructor(props: plantingsProps) {
    super(props);
    this.state = {
      stateOptions: [],
      selectedFile: [],
      selectedState: null,
      districtOptions: [],
      selectedDistrict: null,
      pincodeOptions: [],
      selectedPincode: null,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  componentDidMount() {
    this.getProjectCardDetails();
  }

  shouldComponentUpdate(nextProps: any) {
    if (nextProps.projectId && nextProps.projectId !== this.props.projectId) {
      this.getProjectCardDetails();
    }
    return true;
  }

  getProjectCardDetails = () => {
    projectService.getRegisteredCategoryByData().subscribe(
      (response: any) => {
        try {
          if (response.status && response.statusCode === 200) {
            if (response.data?.length > 0) {
              let rowData: any = [];
              response.data.forEach((element: any) => {
                rowData.push({
                  label: element.name,
                  value: element.id,
                });
              });
              this.setState({ stateOptions: rowData });
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllPlantList(data.selected, pageSize);
  };

  getAllPlantList = (page: number = 1, pageSize: number = 10) => {
    plantService
      .stateDistrictPincodeListing(
        this.state.selectedState?.value,
        this.state.selectedDistrict?.value,
        this.state.selectedPincode?.value,
        page,
        pageSize
      )
      .subscribe(
        (response: any) => {
          let rowData: any = [];
          try {
            if (response.status && response.statusCode === 200) {
              if (response.content?.length > 0) {
                response.content.forEach((element: any) => {
                  let date = moment(element.date_of_planting).format(
                    "DD-MM-YYYY"
                  );
                  let first_name = element.first_name + " " + element.last_name;
                  let status = element.plant_status;
                  let pincode = element.pin_code;
                  let image: any = [];
                  element.images?.forEach((element: any, index: number) => {
                    image.push({
                      key: index,
                      src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                    });
                  });
                  rowData.push({
                    plantedDate: date,
                    dsplayimage: image,
                    first_name: first_name,
                    status: status,
                    pincode: pincode,
                    total_plants_count: element.total_plants_count,
                    dead_plants_count: element.dead_plants_count,
                    live_plants_count: element.live_plants_count,
                  });
                });
                this.setState({
                  pagination: {
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  },
                });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
            this.setState({ selectedFile: rowData });
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
  };
  handlestateChange = (selectedState: any) => {
    this.setState(
      {
        selectedState,
        districtOptions: [],
        selectedDistrict: null,
        pincodeOptions: [],
        selectedPincode: null,
      },
      () => {
        if (selectedState) this.getDistrictByState();
      }
    );
  };
  getDistrictByState = () => {
    try {
      commonService
        .getAllDistrictByStateId(this.state.selectedState?.value)
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                  }));
                  this.setState({ districtOptions: rowData });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleDistrictChange = (selectedDistrict: any) => {
    this.setState(
      {
        selectedDistrict,

        pincodeOptions: [],
        selectedPincode: null,
      },
      () => {
        if (selectedDistrict) this.getAllPincode();
      }
    );
  };
  getAllPincode = () => {
    try {
      commonService
        .getAllPincodeByDistictId(this.state.selectedDistrict?.value)
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                  }));
                  this.setState({ pincodeOptions: rowData });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  handlePincodeChange = (selectedPincode: any) => {
    this.setState({ selectedPincode });
  };
  onSubmit = () => {
    if (Validation.isNotNull(this.state.selectedState, "State")) {
      return false;
    }
    // if (Validation.isNotNull(this.state.selectedDistrict, "District")) {
    //   return false;
    // }
    this.getAllPlantList();
  };
  render() {
    const {
      handlestateChange,
      handleDistrictChange,
      handlePincodeChange,
      onSubmit,
      handlePageClick,
      state: {
        stateOptions,
        selectedState,
        selectedDistrict,
        districtOptions,
        pincodeOptions,
        selectedPincode,
        pagination,
      },
    } = this;

    return (
      <main id="main">
        <section id="MyProfile" className="MyProfile">
          <div className="container" data-aos="fade-up">
            <div className="row ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <h3 className="heading-secondary">List Plantings </h3>
                <div className="section-title">
                  <div className="My_Plantings_PhotoViewBox_head">
                    <div className="row  mb-5">
                      <div className="col-lg-3 col-sm-12">
                        <FieldItem
                          onChange={handlestateChange}
                          label="Category"
                          name="selectedState"
                          value={selectedState}
                          options={stateOptions}
                          placeholder="Select Category"
                          type={FIELD_TYPES.DROP_DOWN}
                          menuPortalTarget={document.querySelector("body")}
                          className="selectRequired"
                          required
                        />
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <FieldItem
                          onChange={handleDistrictChange}
                          label="Area"
                          name="selectedDistrict"
                          value={selectedDistrict}
                          options={districtOptions}
                          placeholder="Select Area"
                          type={FIELD_TYPES.DROP_DOWN}
                          menuPortalTarget={document.querySelector("body")}
                          className="selectRequired"
                          required
                        />
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <FieldItem
                          onChange={handlePincodeChange}
                          label="Pin Code"
                          name="selectedPincode"
                          value={selectedPincode}
                          options={pincodeOptions}
                          placeholder="Select Pin Code"
                          type={FIELD_TYPES.DROP_DOWN}
                          menuPortalTarget={document.querySelector("body")}
                        />
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="mt-4 p-2">
                          <button
                            className="btn  btn-theme-primary"
                            onClick={onSubmit}
                            title="Search"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="My_Plantings_PhotoViewBox_Body">
                    <ul>
                      {this.state.selectedFile?.map(
                        (element: any, index: number) => (
                          <li key={index}>
                            <div className="My_Plantings_PhotoView">
                              {element.dsplayimage.length === 1 ? (
                                <img
                                  className="object_fit"
                                  src={element.dsplayimage[0].src}
                                  alt="project img"
                                />
                              ) : (
                                ""
                              )}
                              {element.dsplayimage.length > 1 ? (
                                <UncontrolledCarousel
                                  controls={true}
                                  indicators={false}
                                  items={element.dsplayimage}
                                />
                              ) : (
                                ""
                              )}
                              {/* <UncontrolledCarousel items={element.dsplayimage} /> */}
                            </div>
                            {/* <h6
                              className="mt-2 text-center form-label"
                              style={{ color: "blue" }}
                            >
                              Plant Status : {element.status}
                            </h6> */}
                            <h6
                              className="mt-2 text-center form-label"
                              style={{ color: "grey" }}
                            >
                              Planted By : {element.first_name}
                            </h6>
                            <h6
                              className="mt-2 text-center form-label"
                              style={{ color: "grey" }}
                            >
                              Planted Count : {element.total_plants_count}
                            </h6>
                            <h6
                              className="mt-2 text-center form-label"
                              style={{ color: "grey" }}
                            >
                              Live Count : {element.live_plants_count}
                            </h6>
                            <h6
                              className="mt-2 text-center form-label"
                              style={{ color: "grey" }}
                            >
                              Dead Count : {element.dead_plants_count}
                            </h6>
                            <h6
                              className="mt-2 text-center form-label"
                              style={{ color: "grey" }}
                            >
                              Planted on : {element.plantedDate}
                            </h6>
                            <h6
                              className="mt-2 text-center form-label"
                              style={{ color: "grey" }}
                            >
                              Pin Code {element.pincode}
                            </h6>

                            {/* <div className="ShareWith_box">
                              <h6 className="m-0 form-label">Share with</h6>
                              <i className="bi bi-facebook"></i>
                            </div> */}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  {Array.isArray(this.state.selectedFile) &&
                  this.state.selectedFile.length ? (
                    <div className="col-md-12 mt-2">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Plantings;
