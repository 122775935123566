import { fetchCall, requestMethod } from "../../utils/ajax";

const renderFile = (fileId: string | number) =>
  fetchCall(`/files/download?file_id=${fileId}`, requestMethod.GET, {});

const getAllDistrictByStateId = (
  stateId: string | number,
  projectId: any = ""
) =>
  fetchCall(
    `/users/registered_districts_by_state?state_id=${stateId}&project_id=${projectId}`,
    requestMethod.GET,
    {}
  );

const getAllPincodeByDistictId = (districtId: string | number) =>
  fetchCall(
    `/users/registered_pincodes_by_district?district_id=${districtId}`,
    requestMethod.GET,
    {}
  );

const getLocalJsonFile = (url: string) =>
  fetchCall(url, requestMethod.GET, {}, {}, false, true);

const getTotalProcuredQuantity = (
  projectId: string,
  stateId: string,
  districtId: string = ""
) =>
  fetchCall(
    `/statistics/procurement?state_id_list=${stateId}&district_id_list=${districtId}&project_id=${projectId}`,
    requestMethod.GET,
    {}
  );

const getAllPlantCount = (projectId: string) =>
  fetchCall(`/statistics?project_id=${projectId}`, requestMethod.GET, {});

const getAllCoutrystats = () => fetchCall(`/cms`, requestMethod.GET, {});

const getAllUserPlantStats = (
  userId: string,
  page: number = 1,
  pageSize: number = 10
) =>
  fetchCall(
    `/users/project_stat?user_id=${userId}&page=${page}&size=${pageSize}&sort=id&order=desc&status=active`,
    requestMethod.GET,
    {}
  );

const commonService = {
  renderFile,
  getAllUserPlantStats,
  getAllDistrictByStateId,
  getAllPincodeByDistictId,
  getLocalJsonFile,
  getTotalProcuredQuantity,
  getAllPlantCount,
  getAllCoutrystats,
};

export default commonService;
