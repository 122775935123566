import { Component } from "react";
import { UserCard, RecentPlantings } from "./partials";
import { FarmerViewProps, FarmerViewState } from "./interface";

import moment from "moment";
import {
  authenticationService,
  dashboardService,
  plantService,
} from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import { UserProjectStats } from "../../volunteerLandingPage/component/user-project-stat";

class FarmerView extends Component<FarmerViewProps, FarmerViewState> {
  constructor(props: FarmerViewProps) {
    super(props);
    this.state = {
      userData: {},
      topPerformer: [],
      selectedFile: [],
      ProjectId: "",
      plantedCount: 0,
      assignedQuantity: 0,
      totalPlantedCount: 0,
      totalLiveCount: 0,
      totalDeadCount: 0,
    };
  }

  componentDidMount() {
    this.setState({
      ProjectId: this.props.projectId,
    });
    this.getUserDetails(this.props.userId || "");
    this.getPerformer();
    this.getAllPlantLists();
    this.getAssigned();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState({
        ProjectId: this.props.projectId,
      });
      this.getPerformer();
      this.getAllPlantLists();
      this.getAssigned();
    }
  }

  getUserDetails = (userId: string) => {
    if (userId) {
      authenticationService.getProfileDetails(userId).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            this.setState({
              userData: {
                id: userId,
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email_id: response.data.email_id,
                mobile_no_1: response.data.mobile_no_1,
                profile_photo_id: response.data.profile_photo_id,
                pincodes_name: response.data.pincodes_name,
                achieved_badges: response.data.achieved_badges,
                invites: response.data.invites,
              },
            });
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    }
  };

  getAllPlantLists = () => {
    try {
      plantService.getAllPlantForUser(this.props.userId || "").subscribe(
        (response: any) => {
          let rowData: any = [];
          let totalcount = 0;
          let totalLiveplant = 0;
          let totalDeadPlant = 0;
          let plantedCount = 0;
          if (response.status && response.statusCode === 200) {
            plantedCount = response.total_planted;
            if (
              Array.isArray(response.content) &&
              response.content?.length > 0
            ) {
              try {
                response.content?.forEach((element: any) => {
                  let date = element.date_of_planting;
                  let place = element.pin_code;
                  let totalPlants = element.total_plants_count;
                  let totalLive = element.live_plants_count;
                  let totalDead = element.dead_plants_count;

                  let image: any = [];
                  if (
                    Array.isArray(element.images) &&
                    element.images.length > 0
                  ) {
                    element.images?.forEach((element: any, index: number) => {
                      image.push({
                        key: index,
                        id: element.image_id,
                      });
                    });
                  }
                  rowData.push({
                    plantedDate: moment(date).format("DD-MM-YYYY"),
                    dsplayimage: image,
                    pin_code: place,
                    totalPlantsCount: totalPlants,
                    totalLiveCount: totalLive,
                    totalDeadCount: totalDead,
                  });
                  totalcount = totalcount + element.total_plants_count;
                  totalLiveplant = totalLiveplant + element.live_plants_count;
                  totalDeadPlant = totalDeadPlant + element.dead_plants_count;
                });
              } catch (error) {
                console.log(error?.message);
              }
            }
          }
          this.setState({
            selectedFile: rowData,
            totalPlantedCount: totalcount,
            totalLiveCount: totalLiveplant,
            totalDeadCount: totalDeadPlant,
            plantedCount,
          });
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  getPerformer = (
    stateId: string = "",
    districtId: string = "",
    pincodeId: string = ""
  ) => {
    try {
      dashboardService
        .getVolunteerWiseData(stateId, districtId, pincodeId)
        .subscribe(
          (response: any) => {
            if (response.statusCode === 200) {
              this.setState({ topPerformer: response.data });
            }
          },
          (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.error(error.message);
    }
  };

  getAssigned = () => {
    try {
      dashboardService
        .getVolunteerAssignedData(this.props.userId || "")
        .subscribe(
          (response: any) => {
            if (response.statusCode === 200) {
              this.setState({
                assignedQuantity: response.data.assigned_quantity,
              });
            }
          },
          (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.error(error.message);
    }
  };

  goBack = () => {
    // this.props.history.goBack();
  };

  render() {
    const {
      userData,
      topPerformer,
      selectedFile,
      assignedQuantity,
      totalPlantedCount,
      totalDeadCount,
      totalLiveCount,
    } = this.state;
    localStorage.setItem("pincode", userData.pincodes_name);

    return (
      <div className="container mt-3 mb-5 landingPage">
        <div className="row m-0 ">
          <div className="col-lg-12">
            <UserCard
              name={`${userData.first_name || ""} ${userData.last_name || ""}`}
              email={userData.email_id || ""}
              mobileNumber={userData.mobile_no_1 || ""}
              pincode={userData.pincodes_name || ""}
              photoId={userData.profile_photo_id || ""}
              achivementData={
                Array.isArray(userData.achieved_badges) &&
                userData.achieved_badges?.length > 0
                  ? userData.achieved_badges[0].badge_name
                  : "-"
              }
              invites={userData.invites || 0}
              plantCount={this.state.plantedCount || 0}
              assignedQuantity={assignedQuantity || 0}
            />
            {/* <div className="mt-3">
              <TopPerformers topPerformer={topPerformer} />
            </div> */}
          </div>
          <div className="col-md-12 mt-3">
            <div className="row gy-3 gx-3">
              <div className=" flexbox-container">
                <div className="planned-card">
                  <div className="planned-text">Total Planted</div>
                  <div className="planned-count">{totalPlantedCount || 0}</div>
                </div>
                <div className="planted-card">
                  <div className="planned-text">Total Live</div>
                  <div className="planned-count">{totalLiveCount || 0}</div>
                </div>
                <div className="dead-card">
                  <div className="planned-text"> Total Dead</div>
                  <div className="planned-count">{totalDeadCount || 0}</div>
                </div>
              </div>
              <div className="col-md-19 mt-3 pro-3-">
                <RecentPlantings
                  selectedFile={selectedFile || 0}
                  userId={this.props.userId || ""}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <h6 className="m-0">Summary</h6>
            <div className="mt-2">
              <UserProjectStats
                userId={this.props.userId || ""}
                projectId={this.props.projectId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FarmerView;
